import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, Typography, Link } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import Icon from "@mdi/react";

import { useDispatch, useSelector } from "react-redux";
import { toggleLeftSidebar } from "../redux/actions/globalStateActions";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";
import Fab from "@material-ui/core/Fab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
// styles
import useStyles from "./styles";

// components
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Dashboard from "../pages/Dashboard";
import UserRoles from "../modules/settings/userRoles";
import UserRoleFunctions from "../modules/settings/roleFunctions";
import Departments from "../modules/settings/departments";
import Organizations from "../modules/settings/organizations";
import Sections from "../modules/settings/sections";
import SubSections from "../modules/settings/subSections";
import User from "../modules/settings/user";
import Objective from "../modules/mykpi/objective";
import Dimention from "../modules/mykpi/bdimention";
import Kpis from "../modules/mykpi/kpis";
import Category from "../modules/userprofile/employeeCategory";
import Designation from "../modules/userprofile/designation";
import Employee from "../modules/userprofile/employees";
import EmployeeKpis from "../modules/mykpi/employeekpis";
import Kpimapping from "../modules/mykpi/kpimappings";
import KpiYears from "../modules/mykpi/kpi_years";
import ScoreCard from "../modules/scoreCard";
import ScoreCard2 from "../modules/scoreCard2";
import Approval from "../notification/Approval";
import ApprovalPanelMembers from "../modules/settings/approvalPanelMembers";
import ApprovalPanel from "../modules/settings/approvalPanel";
import ChangeSupervisor from "../modules/userprofile/changeSupervisor";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [leftOpen, setLeftOpen] = useState(true);
  const [rightOpen, setRightOpen] = useState(false);

  const handleDrawerOpen = () => {
    setLeftOpen(true);
  };

  const handleDrawerClose = () => {
    setLeftOpen(false);
  };

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;

  var [isPermanent, setPermanent] = useState(true);
  const [user, setUser] = useState("");

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem("userInfo"));

    const session = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null;

    const token = "Bearer " + session.access_token;

    console.log("session", session);

    fetch("http://kpi.clonestudiobd.com/api/auth/me", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((userData) => {
        console.log("Checking user Data", userData);
        setUser(userData);
      });
    });
  }, []);

  console.log("user-data", user);

  const token = sessionStorage.getItem("userInfo");

  useEffect(() => {
    dispatch(toggleLeftSidebar);
  }, [dispatch]);

  if (!token) {
    <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} userData={user} />
        <Sidebar userData={user} />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: isSidebarOpened,
          })}
        >
          <div
            className={classnames(classes.fab, {
              [classes.fabShift]: isSidebarOpened,
            })}
          >
            {isPermanent ? (
              <div
                className={classes.fabFixed}
                onClick={() => {
                  dispatch(toggleLeftSidebar);
                }}
              >
                {isSidebarOpened ? (
                  <NavigateBeforeIcon
                    style={{ color: "white", fontSize: 17 }}
                  />
                ) : (
                  <NavigateNextIcon style={{ color: "white", fontSize: 17 }} />
                )}
              </div>
            ) : null}
          </div>
          <div className={classes.fakeToolbar} />

          <div
            style={{
              paddingLeft: isPermanent ? 50 : 10,
              paddingRight: isPermanent ? 50 : 10,
            }}
          >
            <Switch>
              <Route path="/app/me">
                <Dashboard userData={user} />
              </Route>
              <Route path="/app/user">
                <User userData={user} />
              </Route>
              <Route path="/app/user_roles">
                <UserRoles userData={user} />
              </Route>
              <Route path="/app/user_role_functions">
                <UserRoleFunctions userData={user} />
              </Route>
              <Route path="/app/organization_setup">
                <Organizations userData={user} />
              </Route>
              <Route path="/app/departments">
                <Departments userData={user} />
              </Route>
              <Route path="/app/sections">
                <Sections userData={user} />
              </Route>
              <Route path="/app/sub_sections">
                <SubSections userData={user} />
              </Route>
              <Route path="/app/kpi_objectives">
                <Objective userData={user} />
              </Route>
              <Route path="/app/balance_dimentions">
                <Dimention userData={user} />
              </Route>
              <Route path="/app/kpis">
                <Kpis userData={user} />
              </Route>
              <Route path="/app/employee_Categories">
                <Category userData={user} />
              </Route>
              <Route path="/app/designations">
                <Designation userData={user} />
              </Route>
              <Route path="/app/employee_kpis">
                <EmployeeKpis userData={user} />
              </Route>
              <Route path="/app/employees">
                <Employee userData={user} />
              </Route>
              <Route path="/app/kpi_mappings">
                <Kpimapping userData={user} />
              </Route>
              <Route path="/app/kpi_years">
                <KpiYears userData={user} />
              </Route>
              <Route path="/app/score_card">
                <ScoreCard2 userData={user} />
              </Route>
              {/* <Route path="/app/score_card_2">
                <ScoreCard2 userData={user} />
              </Route> */}
              <Route path="/app/approval">
                <Approval />
              </Route>
              <Route path="/app/approval_panel">
                <ApprovalPanel userData={user} />
              </Route>
              <Route path="/app/approval_panel_member">
                <ApprovalPanelMembers userData={user} />
              </Route>
              <Route path="/app/change_supervisor">
                <ChangeSupervisor userData={user} />
              </Route>
              <Redirect exact from="/app" to="/app/me" />
            </Switch>
          </div>

          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="http://clonestudiobd.com/">
                Clone Studio
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </>
    </div>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Layout);
