export const EMPLOYEES_REQUEST = "EMPLOYEES_REQUEST";
export const EMPLOYEES_SUCCESS = "EMPLOYEES_SUCCESS";
export const EMPLOYEES_FAIL = "EMPLOYEES_FAIL";

export const EMPLOYEES_CREATE_REQUEST = "EMPLOYEES_CREATE_REQUEST";
export const EMPLOYEES_CREATE_SUCCESS = "EMPLOYEES_CREATE_SUCCESS";
export const EMPLOYEES_CREATE_FAIL = "EMPLOYEES_CREATE_FAIL";
export const EMPLOYEES_CREATE_RESET = "EMPLOYEES_CREATE_RESET";

export const EMPLOYEES_UPDATE_REQUEST = "EMPLOYEES_UPDATE_REQUEST";
export const EMPLOYEES_UPDATE_SUCCESS = "EMPLOYEES_UPDATE_SUCCESS";
export const EMPLOYEES_UPDATE_FAIL = "EMPLOYEES_UPDATE_FAIL";
export const EMPLOYEES_UPDATE_RESET = "EMPLOYEES_UPDATE_RESET";

export const EMPLOYEES_DELETE_REQUEST = "BALANCEEMPLOYEES_REQUEST";
export const EMPLOYEES_DELETE_SUCCESS = "BALANCEEMPLOYEES_SUCCESS";
export const EMPLOYEES_DELETE_FAIL = "EMPLOYEES_DELETE_FAIL";

export const EMPLOYEES_PERMISSION_REQUEST = "EMPLOYEES_PERMISSION_REQUEST";
export const EMPLOYEES_PERMISSION_SUCCESS = "EMPLOYEES_PERMISSION_SUCCESS";
export const EMPLOYEES_PERMISSION_FAIL = "EMPLOYEES_PERMISSION_FAIL";

//ORGANIZATION
export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST";
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS";
export const ORGANIZATION_FAIL = "ORGANIZATION_FAIL";
//SUBSECTIONS
export const SUB_SECTION_REQUEST = "SUB_SECTION_REQUEST";
export const SUB_SECTION_SUCCESS = "SUB_SECTION_SUCCESS";
export const SUB_SECTION_FAIL = "SUB_SECTION_FAIL";
//designations
export const DESIGNATIONS_REQUEST = "DESIGNATIONS_REQUEST";
export const DESIGNATIONS_SUCCESS = "DESIGNATIONS_SUCCESS";
export const DESIGNATIONS_FAIL = "DESIGNATIONS_FAIL";
//e-catagory
export const EMPLOYEE_CATEGORIES_REQUEST = "EMPLOYEE_CATEGORIES_REQUEST";
export const EMPLOYEE_CATEGORIES_SUCCESS = "EMPLOYEE_CATEGORIES_SUCCESS";
export const EMPLOYEE_CATEGORIES_FAIL = "EMPLOYEE_CATEGORIES_FAIL";
//department
export const DEPARTMENT_REQUEST = "DEPARTMENT_REQUEST";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL";
