export const KPI_MAPPINGS_REQUEST = "KPI_MAPPINGS_REQUEST";
export const KPI_MAPPINGS_SUCCESS = "KPI_MAPPINGS_SUCCESS";
export const KPI_MAPPINGS_FAIL = "KPI_MAPPINGS_FAIL";

export const KPI_MAPPINGS_CREATE_REQUEST = "KPI_MAPPINGS_CREATE_REQUEST";
export const KPI_MAPPINGS_CREATE_SUCCESS = "KPI_MAPPINGS_CREATE_SUCCESS";
export const KPI_MAPPINGS_CREATE_FAIL = "KPI_MAPPINGS_CREATE_FAIL";
export const KPI_MAPPINGS_CREATE_RESET = "KPI_MAPPINGS_CREATE_RESET";

export const KPI_MAPPINGS_UPDATE_REQUEST = "KPI_MAPPINGS_UPDATE_REQUEST";
export const KPI_MAPPINGS_UPDATE_SUCCESS = "KPI_MAPPINGS_UPDATE_SUCCESS";
export const KPI_MAPPINGS_UPDATE_FAIL = "KPI_MAPPINGS_UPDATE_FAIL";
export const KPI_MAPPINGS_UPDATE_RESET = "KPI_MAPPINGS_UPDATE_RESET";

export const KPI_MAPPINGS_DELETE_REQUEST = "KPI_MAPPINGS_DELETE_REQUEST";
export const KPI_MAPPINGS_DELETE_SUCCESS = "KPI_MAPPINGS_DELETE_SUCCESS";
export const KPI_MAPPINGS_DELETE_FAIL = "KPI_MAPPINGS_DELETE_FAIL";

export const KPI_MAPPINGS_PERMISSION_REQUEST = "KPI_MAPPINGS_PERMISSION_REQUEST";
export const KPI_MAPPINGS_PERMISSION_SUCCESS = "KPI_MAPPINGS_PERMISSION_SUCCESS";
export const KPI_MAPPINGS_PERMISSION_FAIL = "KPI_MAPPINGS_PERMISSION_FAIL";
