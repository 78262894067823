import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    department_name,
    setDepartmentName,
    department_alias,
    setDepartmentAlias,
    print_serial,
    setPrintSerial,

    item,
    value,
    handleInputChange,
    error,
    helperText,
    approvalPanels,
    setApprovalPanelId,

    // department_nameCheck,
    // setDepartmentNameCheck,
  } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  var panelName = item
    ? approvalPanels
        .filter((o1) => {
          return o1.id === item.approval_pannel_id;
        })
        .map((item) => item.panel_name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // error={department_nameCheck ? true : false}
              // helperText={
              //   department_nameCheck ? "This field is required" : null
              // }
              name="department_name"
              variant="outlined"
              required
              fullWidth
              id="department_name"
              label="Department Name"
              autoFocus
              size="small"
              defaultValue={item ? item.department_name : null}
              // onChange={(e) => {
              //   setDepartmentName(e.target.value);
              //   setDepartmentNameCheck(false);
              // }}
              error={error.department_name}
              helperText={helperText.department_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="department_alias"
              variant="outlined"
              fullWidth
              id="department_alias"
              label="Department Alias"
              autoFocus
              size="small"
              defaultValue={item ? item.department_alias : null}
              // onChange={(e) => setPhoneNo(e.target.value)}
              value={value.department_alias}
              error={error.department_alias}
              helperText={helperText.department_alias}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="print_serial"
              label="Print Serial"
              autoFocus
              size="small"
              defaultValue={item ? item.print_serial : null}
              // onChange={(e) => setEmailAddress(e.target.value)}
              value={value.print_serial}
              error={error.print_serial}
              helperText={helperText.print_serial}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Approval Panel *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setApprovalPanelId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? panelName : "None"}</option>
                {approvalPanels
                  ? approvalPanels.map((item) => (
                      <option value={item.id}>{item.panel_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
