import {
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,
  DEPARTMENT_CREATE_REQUEST,
  DEPARTMENT_CREATE_SUCCESS,
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_CREATE_RESET,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_RESET,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_PERMISSION_REQUEST,
  DEPARTMENT_PERMISSION_SUCCESS,
  DEPARTMENT_PERMISSION_FAIL,
} from "../constants/departmentConstant";

export const departmentReducer = (state = { departments: [] }, action) => {
  switch (action.type) {
    case DEPARTMENT_REQUEST:
      return { loading: true, departments: [] };
    case DEPARTMENT_SUCCESS:
      return { loading: false, departments: action.payload };
    case DEPARTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const departmentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_CREATE_REQUEST:
      return { loading: true };
    case DEPARTMENT_CREATE_SUCCESS:
      return { loading: false, departmentCreates: action.payload };
    case DEPARTMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const departmentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_UPDATE_REQUEST:
      return { loading: true };
    case DEPARTMENT_UPDATE_SUCCESS:
      return { loading: false, departmentUpdates: action.payload };
    case DEPARTMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DEPARTMENT_UPDATE_RESET:
      return { departmentCreates: {} };
    default:
      return state;
  }
};

export const departmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEPARTMENT_DELETE_REQUEST:
      return { loading: true };
    case DEPARTMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DEPARTMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const departmentPermissionReducer = (
  state = { departmentPermission: [] },
  action
) => {
  switch (action.type) {
    case DEPARTMENT_PERMISSION_REQUEST:
      return { loading: true, departmentPermission: [] };
    case DEPARTMENT_PERMISSION_SUCCESS:
      return { loading: false, departmentPermission: action.payload };
    case DEPARTMENT_PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
