import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setRoleId,
    organizations,
    subSections,
    designations,
    categories,
    employees,
    departments,
    setSupervisorId,
    setDepartmentId,
    setEmployeeCategoryId,
    setDesignationId,
    setSubSectionId,
    setOrganizationId,
    setSameAs,
    approvalPanels,
    item,
    setApprovalPanelId
  } = props;

  console.log("edit data", employees);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  var organizationName = item
    ? organizations
        .filter((o1) => {
          return o1.id === item.organization_id;
        })
        .map((item) => item.organization_name)
    : null;
  console.log("test123", organizationName);

  var subSectionName = item
    ? subSections
        .filter((o1) => {
          return o1.id === item.sub_section_id;
        })
        .map((item) => item.sub_section_name)
    : null;
  console.log("ssn", subSectionName);

  var designationName = item
    ? designations
        .filter((o1) => {
          return o1.id === item.designation_id;
        })
        .map((item) => item.designation)
    : null;

  var eCatagoryName = item
    ? categories
        .filter((o2) => {
          return o2.id === item.employee_category_id;
        })
        .map((item) => item.category)
    : null;

  console.log("catagories", categories);

  var employeeName = item
    ? employees
        .filter((o1) => {
          return o1.id === item.supervisor_id;
        })
        .map((item) => `${item.First_Name} ${item.last_name}`)
    : null;

  var sameAs = item
    ? employees
        .filter((o1) => {
          return o1.id === item.same_as;
        })
        .map((item) => `${item.First_Name} ${item.last_name}`)
    : null;

  var depName = item
    ? departments
        .filter((o1) => {
          return o1.id === item.department_id;
        })
        .map((item) => item.department_name)
    : null;

    var panelName = item
    ? approvalPanels
        .filter((o1) => {
          return o1.id === item.approval_panel_id;
        })
        .map((item) => item.panel_name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="First_Name"
              variant="outlined"
              required
              fullWidth
              id="First_Name"
              label="First Name"
              autoFocus
              size="small"
              defaultValue={item ? item.First_Name : null}
              error={error.First_Name}
              helperText={helperText.First_Name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="last_name"
              variant="outlined"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              autoFocus
              size="small"
              defaultValue={item ? item.last_name : null}
              error={error.last_name}
              helperText={helperText.last_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="org_ref_id"
              variant="outlined"
              required
              fullWidth
              id="org_ref_id"
              label="Org Ref Id"
              autoFocus
              size="small"
              defaultValue={item ? item.org_ref_id : null}
              error={error.org_ref_id}
              helperText={helperText.org_ref_id}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="birth_date"
              variant="outlined"
              required
              fullWidth
              id="birth_date"
              label="Birth Date"
              autoFocus
              size="small"
              type="date"
              defaultValue={item ? item.birth_date : null}
              error={error.birth_date}
              helperText={helperText.birth_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="join_date"
              variant="outlined"
              required
              fullWidth
              id="join_date"
              label="Join Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.join_date : null}
              error={error.join_date}
              helperText={helperText.join_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              required
              fullWidth
              id="phone_no"
              label="Phone No"
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="email_address"
              variant="outlined"
              required
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Organization *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setOrganizationId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? organizationName : "None"}</option>
                {organizations
                  ? organizations.map((item) => (
                      <option value={item.id}>{item.organization_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Sub Section *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setSubSectionId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? subSectionName : "None"}</option>
                {subSections
                  ? subSections.map((item) => (
                      <option value={item.id}>{item.sub_section_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Designation *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setDesignationId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? designationName : "None"}</option>
                {designations
                  ? designations.map((item) => (
                      <option value={item.id}>{item.designation}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Category *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setEmployeeCategoryId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? eCatagoryName : "None"}</option>
                {categories
                  ? categories.map((item) => (
                      <option value={item.id}>{item.category}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Manager/Supervisor Name *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setSupervisorId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? employeeName : "None"}</option>
                {employees
                  ? employees.map((item) => (
                      <option
                        value={item.id}
                      >{`${item.First_Name} ${item.last_name}`}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Department Name *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setDepartmentId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? depName : "None"}</option>
                {departments
                  ? departments.map((item) => (
                      <option value={item.id}>{item.department_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Approval Panel *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setApprovalPanelId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? panelName : "None"}</option>
                {approvalPanels
                  ? approvalPanels.map((item) => (
                      <option value={item.id}>{item.panel_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Same As
              </InputLabel>
              <NativeSelect
                onChange={(e) => setSameAs(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? sameAs : "None"}</option>
                {employees
                  ? employees.map((item) => (
                      <option
                        value={item.id}
                      >{`${item.First_Name} ${item.last_name}`}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
