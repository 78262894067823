import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const { handleInputChange, error, helperText, item } = props;

  console.log("edit data", item);

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="kpi_year"
              variant="outlined"
              required
              fullWidth
              id="kpi_year"
              label="KPI Year"
              autoFocus
              size="small"
              defaultValue={item ? item.kpi_year : null}
              error={error.kpi_year}
              helperText={helperText.kpi_year}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="from_date"
              variant="outlined"
              required
              fullWidth
              id="from_date"
              label="From Date"
              autoFocus
              size="small"
              type="date"
              defaultValue={item ? item.from_date : null}
              error={error.from_date}
              helperText={helperText.from_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="to_date"
              variant="outlined"
              required
              fullWidth
              id="to_date"
              label="To Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.to_date : null}
              error={error.to_date}
              helperText={helperText.to_date}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
