import axios from "axios";
import {
  SECTION_REQUEST,
  SECTION_SUCCESS,
  SECTION_FAIL,
  SECTION_CREATE_REQUEST,
  SECTION_CREATE_SUCCESS,
  SECTION_CREATE_FAIL,
  SECTION_UPDATE_REQUEST,
  SECTION_UPDATE_SUCCESS,
  SECTION_UPDATE_FAIL,
  SECTION_DELETE_REQUEST,
  SECTION_DELETE_SUCCESS,
  SECTION_DELETE_FAIL,
} from "../constants/sectionConstants";

export const sectionList = () => async (dispatch) => {
  try {
    dispatch({
      type: SECTION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/sections",
      config
    );

    dispatch({
      type: SECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SECTION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const sectionCreateAction =
  (section_name, section_alias, department_id, print_serial) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SECTION_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/sections",
        {
          section_name,
          section_alias,
          department_id,
          print_serial,
        },
        config
      );

      dispatch({
        type: SECTION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SECTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const sectionUpdateAction =
  (id, section_name, section_alias, department_id, print_serial) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SECTION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/sections/${id}`,
        { id, section_name, section_alias, department_id, print_serial },
        config
      );

      console.log(
        "update data",
        id,
        section_name,
        section_alias,
        department_id,
        print_serial
      );

      dispatch({
        type: SECTION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SECTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const sectionDeleteAction = (id, setNotify) => async (dispatch) => {
  try {
    dispatch({
      type: SECTION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios
      .delete(`http://kpi.clonestudiobd.com/api/sections/${id}`, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("abcd", error.response);
      });

    console.log("ooo", data);

    dispatch({
      type: SECTION_DELETE_SUCCESS,
    });
  } catch (response) {
    dispatch({
      type: SECTION_DELETE_FAIL,
      payload: response ? response.data : response,
    });
  }
};
