export const KPI_SCORES_REQUEST = "KPI_SCORES_REQUEST";
export const KPI_SCORES_SUCCESS = "KPI_SCORES_SUCCESS";
export const KPI_SCORES_FAIL = "KPI_SCORES_FAIL";

export const KPI_SCORES_CREATE_REQUEST = "KPI_SCORES_CREATE_REQUEST";
export const KPI_SCORES_CREATE_SUCCESS = "KPI_SCORES_CREATE_SUCCESS";
export const KPI_SCORES_CREATE_FAIL = "KPI_SCORES_CREATE_FAIL";
export const KPI_SCORES_CREATE_RESET = "KPI_SCORES_CREATE_RESET";

export const KPI_SCORES_UPDATE_REQUEST = "KPI_SCORES_UPDATE_REQUEST";
export const KPI_SCORES_UPDATE_SUCCESS = "KPI_SCORES_UPDATE_SUCCESS";
export const KPI_SCORES_UPDATE_FAIL = "KPI_SCORES_UPDATE_FAIL";
export const KPI_SCORES_UPDATE_RESET = "KPI_SCORES_UPDATE_RESET";

export const KPI_SCORES_DELETE_REQUEST = "KPI_SCORES_DELETE_REQUEST";
export const KPI_SCORES_DELETE_SUCCESS = "KPI_SCORES_DELETE_SUCCESS";
export const KPI_SCORES_DELETE_FAIL = "KPI_SCORES_DELETE_FAIL";
