export const SUB_SECTION_REQUEST = "SUB_SECTION_REQUEST";
export const SUB_SECTION_SUCCESS = "SUB_SECTION_SUCCESS";
export const SUB_SECTION_FAIL = "SUB_SECTION_FAIL";

export const SUB_SECTION_CREATE_REQUEST = "SUB_SECTION_CREATE_REQUEST";
export const SUB_SECTION_CREATE_SUCCESS = "SUB_SECTION_CREATE_SUCCESS";
export const SUB_SECTION_CREATE_FAIL = "SUB_SECTION_CREATE_FAIL";
export const SUB_SECTION_CREATE_RESET = "SUB_SECTION_CREATE_RESET";

export const SUB_SECTION_UPDATE_REQUEST = "SUB_SECTION_UPDATE_REQUEST";
export const SUB_SECTION_UPDATE_SUCCESS = "SUB_SECTION_UPDATE_SUCCESS";
export const SUB_SECTION_UPDATE_FAIL = "SUB_SECTION_UPDATE_FAIL";
export const SUB_SECTION_UPDATE_RESET = "SUB_SECTION_UPDATE_RESET";

export const SUB_SECTION_DELETE_REQUEST = "SUB_SECTION_DELETE_REQUEST";
export const SUB_SECTION_DELETE_SUCCESS = "SUB_SECTION_DELETE_SUCCESS";
export const SUB_SECTION_DELETE_FAIL = "SUB_SECTION_DELETE_FAIL";
