import {
  KPI_Years_REQUEST,
  KPI_Years_SUCCESS,
  KPI_Years_FAIL,
  KPI_Years_CREATE_REQUEST,
  KPI_Years_CREATE_SUCCESS,
  KPI_Years_CREATE_FAIL,
  KPI_Years_CREATE_RESET,
  KPI_Years_UPDATE_REQUEST,
  KPI_Years_UPDATE_SUCCESS,
  KPI_Years_UPDATE_FAIL,
  KPI_Years_UPDATE_RESET,
  KPI_Years_DELETE_REQUEST,
  KPI_Years_DELETE_SUCCESS,
  KPI_Years_DELETE_FAIL,
} from "../constants/kpiYearsConstant";

export const kpiYearsReducer = (state = { kpiYears: [] }, action) => {
  switch (action.type) {
    case KPI_Years_REQUEST:
      return { loading: true, kpiYears: [] };
    case KPI_Years_SUCCESS:
      return { loading: false, kpiYears: action.payload };
    case KPI_Years_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const kpiYearsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_Years_CREATE_REQUEST:
      return { loading: true };
    case KPI_Years_CREATE_SUCCESS:
      return { loading: false, KpiYearsCreates: action.payload };
    case KPI_Years_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_Years_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const kpiYearsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_Years_UPDATE_REQUEST:
      return { loading: true };
    case KPI_Years_UPDATE_SUCCESS:
      return { loading: false, kpiYearsUpdates: action.payload };
    case KPI_Years_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_Years_UPDATE_RESET:
      return { KPI_YearsCreates: {} };
    default:
      return state;
  }
};

export const kpiYearsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_Years_DELETE_REQUEST:
      return { loading: true };
    case KPI_Years_DELETE_SUCCESS:
      return { loading: false, success: true };
    case KPI_Years_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
