import {
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
  DESIGNATIONS_CREATE_REQUEST,
  DESIGNATIONS_CREATE_SUCCESS,
  DESIGNATIONS_CREATE_FAIL,
  DESIGNATIONS_CREATE_RESET,
  DESIGNATIONS_UPDATE_REQUEST,
  DESIGNATIONS_UPDATE_SUCCESS,
  DESIGNATIONS_UPDATE_FAIL,
  DESIGNATIONS_UPDATE_RESET,
  DESIGNATIONS_DELETE_REQUEST,
  DESIGNATIONS_DELETE_SUCCESS,
  DESIGNATIONS_DELETE_FAIL,
  DESIGNATIONS_PERMISSION_REQUEST,
  DESIGNATIONS_PERMISSION_SUCCESS,
  DESIGNATIONS_PERMISSION_FAIL,
} from "../constants/designationConstant";

export const designationReducer = (state = { designations: [] }, action) => {
  switch (action.type) {
    case DESIGNATIONS_REQUEST:
      return { loading: true, designations: [] };
    case DESIGNATIONS_SUCCESS:
      return { loading: false, designations: action.payload };
    case DESIGNATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const designationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DESIGNATIONS_CREATE_REQUEST:
      return { loading: true };
    case DESIGNATIONS_CREATE_SUCCESS:
      return { loading: false, designationCreates: action.payload };
    case DESIGNATIONS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case DESIGNATIONS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const designationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DESIGNATIONS_UPDATE_REQUEST:
      return { loading: true };
    case DESIGNATIONS_UPDATE_SUCCESS:
      return { loading: false, designationUpdates: action.payload };
    case DESIGNATIONS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case DESIGNATIONS_UPDATE_RESET:
      return { designationCreates: {} };
    default:
      return state;
  }
};

export const designationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DESIGNATIONS_DELETE_REQUEST:
      return { loading: true };
    case DESIGNATIONS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DESIGNATIONS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const designationPermissionReducer = (
  state = { designationPermission: [] },
  action
) => {
  switch (action.type) {
    case DESIGNATIONS_PERMISSION_REQUEST:
      return { loading: true, designationPermission: [] };
    case DESIGNATIONS_PERMISSION_SUCCESS:
      return { loading: false, designationPermission: action.payload };
    case DESIGNATIONS_PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
