import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    dimention: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues({
      dimention: "",
      description: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    dimention: item ? item.dimention : "",
    description: item ? item.description : "",
    // print_serial: item ? item.print_serial : "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      dimention: item.dimention,
      description: item.description,
      // print_serial: item.print_serial,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
