import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { kpiYearsList } from "../../redux/actions/kpiYearsAction";
import { employeesList } from "../../redux/actions/employeeAction";
import { permissionAction } from "../../redux/actions/permissionActions";
import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MonthlyKpiTable from "./MonthlyKpiTable";
import QuarterlyKpiTable from "./Quarterly/QuarterlyKpiTable";
import HalfYearlyKpiTable from "./HalfYearly/QuarterlyKpiTable";
import YearlyKpiTable from "./Yearly/QuarterlyKpiTable";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(12),
  },
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(53);
  const [kpi_year_id, setKpiYearsId] = useState("");
  const [employee_id, setEmployeeId] = useState("");
  const [scoreCardMonthData, setScoreCardMonthData] = useState("");
  const [scoreCardQuarterlyData, setScoreCardQuarterlyData] = useState("");
  const [scoreCardHalfYearlyData, setScoreCardHalfYearlyData] = useState("");
  const [scoreCardYearlyData, setScoreCardYearlyData] = useState("");
  const [getEmployees, setEmployees] = useState("");

  const kpiYear = useSelector((state) => state.kpiYear);

  const { kpiYears } = kpiYear;

  const employee = useSelector((state) => state.employee);

  const { employees } = employee;

  console.log("userData", userData);

  useEffect(() => {
    fetch(
      "http://kpi.clonestudiobd.com/api/employees/employee-with-supervisor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          supervisor_id: userData.employee_id,
        }),
      }
    ).then((response) => {
      response.json().then((result) => setEmployees(result));
    });
  }, [userData]);

  useEffect(() => {
    if (kpiYears.length && getEmployees.length) {
      setKpiYearsId(kpiYears[0].id);
      setEmployeeId(getEmployees[0].id);
    }
  }, [kpiYears, getEmployees]);

  const monthlyData = [
    {
      id: 1,
      kpi_name: "KPI-1",
      kpi: [
        {
          id: 1,
          kpi_name: "KPI-1",
        },
      ],
    },
    {
      id: 2,
      kpi_name: "KPI-2",
    },
    {
      id: 3,
      kpi_name: "KPI-3",
    },
  ];

  useEffect(() => {
    dispatch(permissionAction(userData, app_menu_id));
    dispatch(kpiYearsList());
    dispatch(employeesList());
  }, [dispatch, userData]);

  const generateKpi = () => {
    fetch("http://kpi.clonestudiobd.com/api/card-with-kpi", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        kpi_year_id: kpi_year_id,
        employee_id: employee_id,
      }),
    }).then((response) => {
      response.json().then((userData) => {
        console.log(response.status);

        if (response.status === 200) {
          fetch("http://kpi.clonestudiobd.com/api/kpiscores/monthly-scores", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              kpi_year_id: kpi_year_id,
              employee_id: employee_id,
            }),
          }).then((response) => {
            response.json().then((userData) => {
              console.log(response.status);

              console.log("Checking month Data", userData);
              setScoreCardMonthData(userData);
            });
          });

          fetch(
            "https://kpi.clonestudiobd.com/api/kpi-scores-update/monthly-scores",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Credentials": true,
              },
              body: JSON.stringify({
                kpi_year_id: kpi_year_id,
                employee_id: employee_id,
              }),
            }
          ).then((response) => {
            response.json().then((userData) => {
              console.log(response.status);
              console.log("score-update", userData);
            });
          });

          fetch("http://kpi.clonestudiobd.com/api/kpiscores/quaterly_scores", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              kpi_year_id: kpi_year_id,
              employee_id: employee_id,
            }),
          }).then((response) => {
            response.json().then((userData) => {
              console.log(response.status);

              console.log("Checking Quarterly Data", userData);
              setScoreCardQuarterlyData(userData);
            });
          });

          fetch(
            "http://kpi.clonestudiobd.com/api/kpiscores/half-yearly-scores",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                kpi_year_id: kpi_year_id,
                employee_id: employee_id,
              }),
            }
          ).then((response) => {
            response.json().then((userData) => {
              console.log(response.status);

              console.log("Checking Yearly Data", userData);
              setScoreCardHalfYearlyData(userData);
            });
          });

          fetch("http://kpi.clonestudiobd.com/api/kpiscores/yearly_scores", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              kpi_year_id: kpi_year_id,
              employee_id: employee_id,
            }),
          }).then((response) => {
            response.json().then((userData) => {
              console.log(response.status);

              console.log("Checking Yearly Data", userData);
              setScoreCardYearlyData(userData);
            });
          });
        }
        console.log("Checking user Data", userData);
      });
    });
  };

  useEffect(() => {
    if (kpi_year_id !== null && employee_id !== null) {
      generateKpi();
    }
  }, [kpi_year_id, employee_id]);

  const classes = useStyles();

  return (
    <div style={{ minHeight: "80vh" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>timeline</Icon>
        <h2 style={{ marginLeft: 5 }}>Score Card</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Score Card</p>
      </div>

      <div style={{ marginTop: 20, flexGrow: 1 }}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Select KPI Year
              </InputLabel>
              <Select
                native
                value={kpi_year_id}
                onChange={(e) => setKpiYearsId(e.target.value)}
                label="Select Employee"
                inputProps={{
                  name: "Select Employee",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                {kpiYears
                  ? kpiYears.map((item) => (
                      <option value={item.id}>{item.kpi_year}</option>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Select Employee
              </InputLabel>
              <Select
                native
                value={employee_id}
                onChange={(e) => setEmployeeId(e.target.value)}
                label="Select Employee"
                inputProps={{
                  name: "Select Employee",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                {getEmployees
                  ? getEmployees.map((item) => (
                      <option value={item.id}>
                        {item.First_Name} {item.last_name}
                      </option>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={6}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => generateKpi()}
              >
                Generate KPI
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button variant="contained" color="primary">
                Print
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container item xs={8}>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Select KPI Year
                </InputLabel>
                <Select
                  native
                  value={kpi_year_id}
                  onChange={(e) => setKpiYearsId(e.target.value)}
                  label="Select Employee"
                  inputProps={{
                    name: "Select Employee",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  {kpiYears
                    ? kpiYears.map((item) => (
                        <option value={item.id}>{item.kpi_year}</option>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Select Employee
                </InputLabel>
                <Select
                  native
                  value={employee_id}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  label="Select Employee"
                  inputProps={{
                    name: "Select Employee",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  {getEmployees
                    ? getEmployees.map((item) => (
                        <option value={item.id}>
                          {item.First_Name} {item.last_name}
                        </option>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid
              item
              xs={6}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => generateKpi()}
              >
                Generate KPI
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button variant="contained" color="primary">
                Print
              </Button>
            </Grid>
          </Grid> */}
        </Grid>

        <div>
          {scoreCardMonthData.length ? (
            <MonthlyKpiTable
              monthlyData={scoreCardMonthData}
              userData={userData}
            />
          ) : null}
          {scoreCardQuarterlyData.length ? (
            <QuarterlyKpiTable
              monthlyData={scoreCardQuarterlyData}
              userData={userData}
            />
          ) : null}
          {scoreCardHalfYearlyData.length ? (
            <HalfYearlyKpiTable
              monthlyData={scoreCardHalfYearlyData}
              userData={userData}
            />
          ) : null}
          {scoreCardYearlyData.length ? (
            <YearlyKpiTable
              monthlyData={scoreCardYearlyData}
              userData={userData}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Body;
