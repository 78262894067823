import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  employeesList,
  employeesUpdateAction,
} from "../../../redux/actions/employeeAction";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    departments,
    organizations,
    subSections,
    designations,
    categories,
    setNotify,
    employees,
    openEditPopup,
    setOpenEditPopup,
    approvalPanels,
    item,
  } = props;

  const [organization_id, setOrganizationId] = React.useState("");
  const [sub_section_id, setSubSectionId] = React.useState("");
  const [designation_id, setDesignationId] = React.useState("");
  const [employee_category_id, setEmployeeCategoryId] = React.useState("");
  const [supervisor_id, setSupervisorId] = React.useState("");
  const [department_id, setDepartmentId] = React.useState("");
  const [same_as, setSameAs] = React.useState("");
  const [approval_panel_id, setApprovalPanelId] = React.useState("");


  const id = item.id;

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setOrganizationId(item.organization_id);
    setSubSectionId(item.sub_section_id);
    setDesignationId(item.designation_id);
    setEmployeeCategoryId(item.employee_category_id);
    setSupervisorId(item.supervisor_id);
    setDepartmentId(item.department_id);
    setSameAs(item.same_as);
    setApprovalPanelId(item.approval_panel_id)
  }, [item]);

  const dispatch = useDispatch();
  const employeeUpdate = useSelector((state) => state.employeeUpdate);
  const { employeeUpdates } = employeeUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("phone_no" in fieldValues)
      temp.phone_no = fieldValues.phone_no ? "" : "This field is required.";

    if ("email_address" in fieldValues)
      temp.email_address = fieldValues.email_address
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);
  console.log("employee", values);

  const First_Name = values.First_Name;
  const last_name = values.last_name;
  const org_ref_id = values.org_ref_id;
  const birth_date = values.birth_date;
  const join_date = values.join_date;
  const phone_no = values.phone_no;
  const email_address = values.email_address;

  console.log("rrrrrr", same_as);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        employeesUpdateAction(
          id,
          First_Name,
          last_name,
          org_ref_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          organization_id,
          sub_section_id,
          designation_id,
          employee_category_id,
          supervisor_id,
          department_id,
          approval_panel_id,
          same_as
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(employeesList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openEditPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Employee
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setOrganizationId={setOrganizationId}
          setSubSectionId={setSubSectionId}
          setDesignationId={setDesignationId}
          setEmployeeCategoryId={setEmployeeCategoryId}
          setSupervisorId={setSupervisorId}
          setDepartmentId={setDepartmentId}
          item={item}
          organizations={organizations}
          subSections={subSections}
          designations={designations}
          categories={categories}
          employees={employees}
          departments={departments}
          value={values}
          setSameAs={setSameAs}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          approvalPanels={approvalPanels}
          setApprovalPanelId={setApprovalPanelId}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
