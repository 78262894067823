export const APPROVAL_PANEL_MEMBERS_REQUEST = "APPROVAL_PANEL_MEMBERS_REQUEST";
export const APPROVAL_PANEL_MEMBERS_SUCCESS = "APPROVAL_PANEL_MEMBERS_SUCCESS";
export const APPROVAL_PANEL_MEMBERS_FAIL = "APPROVAL_PANEL_MEMBERS_FAIL";

export const APPROVAL_PANEL_MEMBERS_CREATE_REQUEST =
  "APPROVAL_PANEL_MEMBERS_CREATE_REQUEST";
export const APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS =
  "APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS";
export const APPROVAL_PANEL_MEMBERS_CREATE_FAIL =
  "APPROVAL_PANEL_MEMBERS_CREATE_FAIL";
export const APPROVAL_PANEL_MEMBERS_CREATE_RESET =
  "APPROVAL_PANEL_MEMBERS_CREATE_RESET";

export const APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST =
  "APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST";
export const APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS =
  "APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS";
export const APPROVAL_PANEL_MEMBERS_UPDATE_FAIL =
  "APPROVAL_PANEL_MEMBERS_UPDATE_FAIL";
export const APPROVAL_PANEL_MEMBERS_UPDATE_RESET =
  "APPROVAL_PANEL_MEMBERS_UPDATE_RESET";

export const APPROVAL_PANEL_MEMBERS_DELETE_REQUEST =
  "APPROVAL_PANEL_MEMBERS_DELETE_REQUEST";
export const APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS =
  "APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS";
export const APPROVAL_PANEL_MEMBERS_DELETE_FAIL =
  "APPROVAL_PANEL_MEMBERS_DELETE_FAIL";

export const APPROVAL_PANEL_MEMBERS_PERMISSION_REQUEST =
  "APPROVAL_PANEL_MEMBERS_PERMISSION_REQUEST";
export const APPROVAL_PANEL_MEMBERS_PERMISSION_SUCCESS =
  "APPROVAL_PANEL_MEMBERS_PERMISSION_SUCCESS";
export const APPROVAL_PANEL_MEMBERS_PERMISSION_FAIL =
  "APPROVAL_PANEL_MEMBERS_PERMISSION_FAIL";
