import axios from "axios";
import {
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAIL,
  ORGANIZATION_CREATE_REQUEST,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_CREATE_FAIL,
  ORGANIZATION_UPDATE_REQUEST,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_UPDATE_FAIL,
  ORGANIZATION_DELETE_REQUEST,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_DELETE_FAIL,
  ORGANIZATION_PERMISSION_REQUEST,
  ORGANIZATION_PERMISSION_SUCCESS,
  ORGANIZATION_PERMISSION_FAIL,
} from "../constants/organizationConstant";

export const organizationList = () => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/organizations"
    );

    dispatch({
      type: ORGANIZATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const organizationCreateAction =
  (
    organization_name,
    organization_alias,
    organization_type,
    address,
    phone_no,
    email_address,
    website,
    logo,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORGANIZATION_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("organization_name", organization_name);
      formData.append("organization_alias", organization_alias);
      formData.append("organization_type", organization_type);
      formData.append("address", address);
      formData.append("phone_no", phone_no);
      formData.append("email_address", email_address);
      formData.append("website", website);
      formData.append("logo", logo);

      console.log("formData", formData);

      const config = {
        headers: {
          // Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          "http://kpi.clonestudiobd.com/api/organizations",
          formData,
          config
        )
        .then((res) => {
          console.log(res.data);
        });

      console.log("testDAta", data);

      dispatch({
        type: ORGANIZATION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORGANIZATION_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const organizationUpdateAction =
  (
    id,
    organization_name,
    organization_alias,
    organization_type,
    address,
    phone_no,
    email_address,
    website,
    logo,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ORGANIZATION_UPDATE_REQUEST,
      });

      console.log("id", id);

      console.log("organization_name", organization_name);
      console.log("organization_alias", organization_alias);
      console.log("organization_type", organization_type);
      console.log("address", address);
      console.log("phone_no", phone_no);
      console.log("email_address", email_address);
      console.log("website", website);
      console.log("logo", logo);

      const formData = new FormData();
      formData.append("organization_name", organization_name);
      formData.append("organization_alias", organization_alias);
      formData.append("organization_type", organization_type);
      formData.append("address", address);
      formData.append("phone_no", phone_no);
      formData.append("email_address", email_address);
      formData.append("website", website);
      formData.append("logo", logo);

      console.log("Data", formData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `http://kpi.clonestudiobd.com/api/organizations/${id}`,
          {
            organization_name: organization_name,
            organization_alias: organization_alias,
            organization_type: organization_type,
            address: address,
            phone_no: phone_no,
            email_address: email_address,
            website: website,
            logo: logo,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      console.log(
        "update data",
        organization_name,
        organization_alias,
        organization_type,
        address,
        phone_no,
        email_address,
        website,
        logo,
        created_by,
        modified_by
      );

      dispatch({
        type: ORGANIZATION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORGANIZATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const organizationDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/organizations/${id}`,
      config
    );

    dispatch({
      type: ORGANIZATION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const organizationPermissionAction =
  (user_role_id, app_menu_id) => async (dispatch) => {
    try {
      dispatch({
        type: ORGANIZATION_PERMISSION_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      console.log("DataCheck", user_role_id, app_menu_id);

      const { data } = await axios.post(
        "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
        {
          user_role_id: user_role_id,
          app_menu_id: app_menu_id,
        },
        config
      );

      console.log("DataP", user_role_id, app_menu_id);

      dispatch({
        type: ORGANIZATION_PERMISSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORGANIZATION_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
