import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  designationList,
  designationPermissionAction,
} from "../../../redux/actions/designationAction";
import { approvalPanelMembersList } from "../../../redux/actions/approvalPanelMembersAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import { approvalPanelList } from "../../../redux/actions/approvalPanelAction";
import { userList } from "../../../redux/actions/userActions";
import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(58);

  const approvalPanelMember = useSelector((state) => state.approvalPanelMember);

  const { approvalPanelMembers } = approvalPanelMember;

  const approvalPanel = useSelector((state) => state.approvalPanel);

  const { approvalPanels } = approvalPanel;

  const user = useSelector((state) => state.user);
  const { users } = user;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", approvalPanelMembers);
  console.log(userData);

  const result = [];
  approvalPanelMembers.forEach((e, i) => {
    const indexOfExisting = result.findIndex(
      (x) => x.id === e.approval_panel_id
    );
    if (indexOfExisting === -1) {
      result.push({
        id: e.approval_panel_id,
        members_with_sequence: [
          `${e.first_name} ${e.last_name} (Sequence No : ${e.sequence_No})`,
        ],
      });
    } else {
      result[indexOfExisting].members_with_sequence.push(
        `${e.first_name} ${e.last_name} (Sequence No : ${e.sequence_No})`
      );
    }
  });

  console.log("kkkk", result);

  useEffect(() => {
    dispatch(designationList());
    dispatch(approvalPanelList());
    dispatch(userList());
    dispatch(approvalPanelMembersList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>settings</Icon>
        <h2 style={{ marginLeft: 5 }}>Approval Panel Members</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Approval Panel Members</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          approvalPanelMembers={result}
          allApprovalPanelMembers={approvalPanelMembers}
          approvalPanels={approvalPanels}
          users={users}
          permissions={permissions}
        />
      </div>
    </div>
  );
};

export default Body;
