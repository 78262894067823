export const KPI_Years_REQUEST = "KPI_Years_REQUEST";
export const KPI_Years_SUCCESS = "KPI_Years_SUCCESS";
export const KPI_Years_FAIL = "KPI_Years_FAIL";

export const KPI_Years_CREATE_REQUEST = "KPI_Years_CREATE_REQUEST";
export const KPI_Years_CREATE_SUCCESS = "KPI_Years_CREATE_SUCCESS";
export const KPI_Years_CREATE_FAIL = "KPI_Years_CREATE_FAIL";
export const KPI_Years_CREATE_RESET = "KPI_Years_CREATE_RESET";

export const KPI_Years_UPDATE_REQUEST = "KPI_Years_UPDATE_REQUEST";
export const KPI_Years_UPDATE_SUCCESS = "KPI_Years_UPDATE_SUCCESS";
export const KPI_Years_UPDATE_FAIL = "KPI_Years_UPDATE_FAIL";
export const KPI_Years_UPDATE_RESET = "KPI_Years_UPDATE_RESET";

export const KPI_Years_DELETE_REQUEST = "KPI_Years_DELETE_REQUEST";
export const KPI_Years_DELETE_SUCCESS = "KPI_Years_DELETE_SUCCESS";
export const KPI_Years_DELETE_FAIL = "KPI_Years_DELETE_FAIL";
