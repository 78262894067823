import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyle = makeStyles((theme) => ({
  root: {
    top: theme.spacing(9),
    right: theme.spacing(11),
  },
}));

function Notification(props) {
  const { notify, setNotify } = props;
  const classes = useStyle();
  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      autoHideDuration={1000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => setNotify({ isOpen: false })}
    >
      <Alert severity='success'>{notify.message}</Alert>
    </Snackbar>
  );
}

export default Notification;
