import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  approvalPanelMembersUpdateAction,
  approvalPanelMembersList,
} from "../../../redux/actions/approvalPanelMembersAction";
import { EditValidation } from "./FormValidation";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    approvalPanels,
    approvalPanelMembers,
    allApprovalPanelMembers,
    users,
  } = props;

  const id = item.id;
  const [products, setProducts] = React.useState("");
  const [postData, setPostData] = React.useState("");

  const created_by = "Shahibuzzaman";
  const modified_by = "Shahibuzzaman2";

  var panelName = approvalPanels
    .filter((item) => {
      return item.id === id;
    })
    .map((item) => item.panel_name);

  console.log("id", panelName);

  React.useEffect(() => {
    const data = allApprovalPanelMembers.length
      ? allApprovalPanelMembers
          .filter((item) => {
            return item.approval_panel_id === id;
          })
          .map((item) => item)
      : null;

    setProducts(data);
  }, [item, allApprovalPanelMembers]);

  const dispatch = useDispatch();
  const designationUpdate = useSelector((state) => state.designationUpdate);
  const { designationUpdates } = designationUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("designation" in fieldValues)
      temp.designation = fieldValues.designation
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  console.log("lll", products);

  const designation = values.designation;
  const alias = values.alias;
  const print_serial = values.print_serial;

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(approvalPanelMembersUpdateAction(products));
    setOpenEditPopup(false);
    setTimeout(() => {
      dispatch(approvalPanelMembersList());
    }, 1000);
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  const handleAddEvent = () => {
    var _id = Math.floor(Math.random() * 100);
    var product = {
      id: _id,
      approval_panel_id: id,
      user_id: "",
      sequence_No: "",
    };

    setProducts((currentArray) => [...currentArray, product]);
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openEditPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Approval Panel Members
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          items={item}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
          panelName={panelName}
          products={products}
          users={users}
          setProducts={setProducts}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20, flex: 1 }}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={handleAddEvent}
              style={{ marginRight: 10 }}
            >
              Add New Row
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={handleClose}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={submitHandler}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
