import React, { useReducer, useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Chip } from "@material-ui/core";

import NativeSelect from "@material-ui/core/NativeSelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = { selectedOptions: [] };

function reducer(state, action) {
  switch (action.type) {
    case "SET_SELECTED_OPTIONS":
      return { selectedOptions: action.payload.options };
    case "REMOVE_OPTION":
      return {
        selectedOptions: state.selectedOptions.filter(
          (option) => option.id !== action.payload.id
        ),
      };
    case "RESET":
      return initialState;
    default:
      throw new Error();
  }
}

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setDepartmentId,
    setKpiId,
    setUserStatus,
    items,
    departments,
    kpis,
    kpi_id,
    setPostData,
    kpimappings,
  } = props;

  console.log("edit data", items);

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange2 = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch({ type: "REMOVE_OPTION", payload: { id: id } });
  };

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  // var sectionName = item
  //   ? sections
  //       .filter((o1) => {
  //         return o1.id === item.section_id;
  //       })
  //       .map((item) => item.section_name)
  //   : null;
  // var sectionName = item
  //   ? sections
  //       .filter((o1) => {
  //         return o1.id === item.section_id;
  //       })
  //       .map((item) => item.section_name)
  //   : null;

  // var departmentName = item
  //   ? departments
  //       .filter((o1) => {
  //         return o1.id === item.department_id;
  //       })
  //       .map((item) => item.department_name)
  //   : null;
  var kpiName = items
    ? kpis
        .filter((o1) => {
          return o1.id === items.kpi_id;
        })
        .map((item) => item.kpi)
    : null;

  //   var departmentName = departments
  //   .filter((x) => {
  //     return x.id === item.department_id;
  //   })
  //   .map((item) => item.department_name);
  // var kpiName = kpis
  //   .filter((x) => {
  //     return x.id === item.kpi_id;
  //   })
  //   .map((item) => item.kpi);

  console.log("fffff", JSON.stringify(state.selectedOptions, null, 2));

  state.selectedOptions.forEach(
    (item) => (item.kpi_id = items ? items.kpi_id : `${kpi_id}`)
  );
  state.selectedOptions.forEach((item) =>
    items ? item : (item.department_id = item.id)
  );
  // console.log("add kpi", JSON.stringify(state.selectedOptions, null, 2));

  useEffect(() => {
    setPostData(state.selectedOptions);
  }, [state.selectedOptions]);

  useEffect(() => {
    const editData =
      kpimappings && items
        ? kpimappings
            .filter((item) => item.kpi_id === items.kpi_id)
            .map((item) => item)
        : null;

    console.log("ooo", editData);

    if (editData !== null) {
      dispatch({
        type: "SET_SELECTED_OPTIONS",
        payload: {
          options: editData,
        },
      });
    }

    console.log("jjjjj", initialState);
  }, [items, kpimappings]);

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Section *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setRoleId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? sectionName : "None"}</option>
                {sections
                  ? sections.map((item) => (
                      <option value={item.id}>{item.section_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                KPI *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setKpiId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{items ? kpiName : "None"}</option>
                {items
                  ? null
                  : kpis
                  ? kpis.map((item) => (
                      <option value={item.id}>{item.kpi}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Department *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setDepartmentId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? departmentName : "None"}</option>
                {departments
                  ? departments.map((item) => (
                      <option value={item.id}>{item.department_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <Autocomplete
                fullWidth={true}
                size="medium"
                multiple
                options={departments}
                disableCloseOnSelect
                getOptionLabel={(option) => option.department_name}
                value={state.selectedOptions}
                renderTags={(values) =>
                  values.map((value) => (
                    <Chip
                      key={value.id}
                      style={{ marginRight: 8 }}
                      label={value.department_name}
                      onDelete={() => {
                        removeOption(value.id);
                      }}
                    />
                  ))
                }
                getOptionSelected={(option, value) =>
                  option.department_name === value.department_name
                }
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.department_name}
                  </React.Fragment>
                )}
                style={{ width: "100%" }}
                onChange={handleChange2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Departments*"
                    placeholder="Type here..."
                  />
                )}
              />
            </FormControl>
          </Grid>

          {/* 
          <Grid item xs={12}>
            <TextField
              name="chapter_no"
              variant="outlined"
              required
              fullWidth
              id="chapter_no"
              label="Sub-Section Name"
              autoFocus
              size="small"
              error={error.chapter_no}
              helperText={helperText.chapter_no}
              defaultValue={item ? item.sub_section_name : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="chapter_title"
              required
              variant="outlined"
              fullWidth
              id="chapter_title"
              label="Sub-Section Alias"
              autoFocus
              size="small"
              error={error.chapter_title}
              helperText={helperText.chapter_title}
              defaultValue={item ? item.sub_section_alias : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="user_id"
              label="Print Serial"
              autoFocus
              size="small"
              error={error.print_serial}
              helperText={helperText.print_serial}
              defaultValue={item ? item.print_serial : null}
              onChange={handleInputChange}
            />
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
