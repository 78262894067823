export const EMPLOYEE_CATEGORIES_REQUEST = "EMPLOYEE_CATEGORIES_REQUEST";
export const EMPLOYEE_CATEGORIES_SUCCESS = "EMPLOYEE_CATEGORIES_SUCCESS";
export const EMPLOYEE_CATEGORIES_FAIL = "EMPLOYEE_CATEGORIES_FAIL";

export const EMPLOYEE_CATEGORIES_CREATE_REQUEST = "EMPLOYEE_CATEGORIES_CREATE_REQUEST";
export const EMPLOYEE_CATEGORIES_CREATE_SUCCESS = "EMPLOYEE_CATEGORIES_CREATE_SUCCESS";
export const EMPLOYEE_CATEGORIES_CREATE_FAIL = "EMPLOYEE_CATEGORIES_CREATE_FAIL";
export const EMPLOYEE_CATEGORIES_CREATE_RESET = "EMPLOYEE_CATEGORIES_CREATE_RESET";

export const EMPLOYEE_CATEGORIES_UPDATE_REQUEST = "EMPLOYEE_CATEGORIES_UPDATE_REQUEST";
export const EMPLOYEE_CATEGORIES_UPDATE_SUCCESS = "EMPLOYEE_CATEGORIES_UPDATE_SUCCESS";
export const EMPLOYEE_CATEGORIES_UPDATE_FAIL = "EMPLOYEE_CATEGORIES_UPDATE_FAIL";
export const EMPLOYEE_CATEGORIES_UPDATE_RESET = "EMPLOYEE_CATEGORIES_UPDATE_RESET";

export const EMPLOYEE_CATEGORIES_DELETE_REQUEST = "EMPLOYEE_CATEGORIES_DELETE_REQUEST";
export const EMPLOYEE_CATEGORIES_DELETE_SUCCESS = "EMPLOYEE_CATEGORIES_DELETE_SUCCESS";
export const EMPLOYEE_CATEGORIES_DELETE_FAIL = "EMPLOYEE_CATEGORIES_DELETE_FAIL";

export const EMPLOYEE_CATEGORIES_PERMISSION_REQUEST = "EMPLOYEE_CATEGORIES_PERMISSION_REQUEST";
export const EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS = "EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS";
export const EMPLOYEE_CATEGORIES_PERMISSION_FAIL = "EMPLOYEE_CATEGORIES_PERMISSION_FAIL";
