import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setLastName,
    setRoleId,
    setEmployeeId,
    setPhoto,
    user_status,
    setUserStatus,
    item,
    roles,
    employees,
    setSignPhoto,
    value,
    handleInputChange,
    error,
    helperText,
    employee_id,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    setPhoto(files[0]);
  };

  const handleSignPhotoUpload = (event) => {
    const files = event.target.files;
    setSignPhoto(files[0]);
  };

  var roleName = item
    ? roles
        .filter((o1) => {
          return o1.id === item.user_role_id;
        })
        .map((item) => item.role_name)
    : null;

  var employeeName = item
    ? employees
        .filter((o1) => {
          return o1.id === item.employee_id;
        })
        .map((item) => item.First_Name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="first_name"
              variant="outlined"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              size="small"
              error={error.first_name}
              helperText={helperText.first_name}
              defaultValue={item ? item.first_name : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="last_name"
              variant="outlined"
              fullWidth
              id="last_name"
              label="Last Name"
              autoFocus
              size="small"
              defaultValue={item ? item.last_name : null}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="user_id"
              variant="outlined"
              required
              fullWidth
              id="user_id"
              label="User Id"
              autoFocus
              size="small"
              error={error.user_id}
              helperText={helperText.user_id}
              defaultValue={item ? item.user_id : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextField
              name="employee_id"
              variant="outlined"
              fullWidth
              id="employee_id"
              label="Employee ID"
              autoFocus
              size="small"
              defaultValue={item ? item.employee_id : null}
              onChange={(e) => setEmployeeId(e.target.value)}
            /> */}
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Employee Name
              </InputLabel>
              <NativeSelect
                // value={item? item.}
                onChange={(e) => setEmployeeId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-label-placeholder",
                }}
              >
                <option value="">{item ? employeeName : "None"}</option>
                {employees
                  ? employees.map((item) => (
                      <option value={item.id}>{item.First_Name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoFocus
              size="small"
              error={error.email}
              helperText={helperText.email}
              defaultValue={item ? item.email : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              required
              fullWidth
              id="phone_no"
              label="Phone Number"
              autoFocus
              size="small"
              error={error.phone_no}
              helperText={helperText.phone_no}
              defaultValue={item ? item.phone_no : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              label="Password"
              multiline
              required
              fullWidth
              size="small"
              variant="outlined"
              error={error.password}
              helperText={helperText.password}
              defaultValue={item ? item.user_password : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">User Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status1"
                defaultValue={item ? item.user_status : user_status}
                onChange={handleChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="A"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Active</Typography>
                  }
                />
                <FormControlLabel
                  value="D"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Deactivate</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Photo</FormLabel>
              <input
                accept='image/*'
                id='contained-button-file'
                multiple
                type='file'
                onChange={handlePhotoUpload}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Role Name
              </InputLabel>
              <NativeSelect
                // value={item? item.}
                onChange={(e) => setRoleId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-label-placeholder",
                }}
              >
                <option value="">{item ? roleName : "None"}</option>
                {roles
                  ? roles.map((item) => (
                      <option value={item.id}>{item.role_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Signature</FormLabel>
              <input
                accept='image/*'
                id='contained-button-file'
                multiple
                type='file'
                onChange={handleSignPhotoUpload}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
