import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { employeesList } from "../../../redux/actions/employeeAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Notification from "./Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: "60vh",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(58);
  const [old_supervisor_id, setOldSupervisor] = useState("");
  const [new_supervisor_id, setNewSupervisor] = useState("");
  const [left, setLeft] = React.useState("");
  const [right, setRight] = React.useState([]);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const employee = useSelector((state) => state.employee);
  const { employees } = employee;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", permissions);
  console.log(userData);

  useEffect(() => {
    dispatch(employeesList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  useEffect(() => {
    fetch(
      "http://kpi.clonestudiobd.com/api/employees/employee-with-supervisor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          supervisor_id: old_supervisor_id,
        }),
      }
    ).then((response) =>
      response.json().then((result) => {
        result.shift();
        setLeft(result);
        console.log("refine", result);
      })
    );
  }, [old_supervisor_id]);

  useEffect(() => {
    fetch(
      "http://kpi.clonestudiobd.com/api/employees/employee-with-supervisor",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          supervisor_id: new_supervisor_id,
        }),
      }
    ).then((response) =>
      response.json().then((result) => {
        result.shift();
        setRight(result);
        console.log("refine", result);
      })
    );
  }, [new_supervisor_id]);

  const submitReplaceHandler = (e) => {
    e.preventDefault();
    fetch("http://kpi.clonestudiobd.com/api/employees/replace-supervisor", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        new_supervisor_id: new_supervisor_id,
        data: right,
      }),
    }).then((response) =>
      response.json().then((result) =>
        setNotify({
          isOpen: true,
          message: "Updated Successfully!",
          type: "success",
        })
      )
    );

    setOldSupervisor("");
    setNewSupervisor("");
  };

  const submitClearHandler = (e) => {
    e.preventDefault();

    setOldSupervisor("");
    setNewSupervisor("");
  };

  const classes = useStyles();

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  console.log("right", JSON.stringify(right));
  console.log("leftChecked", leftChecked);

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customLeftList = (title, items) => (
    <Card
      style={{
        display: "block",
        transitionDuration: "0.3s",
        height: "60vh",
      }}
    >
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {left !== ""
          ? left.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${value.First_Name} ${value.last_Name}`}
                  />
                </ListItem>
              );
            })
          : null}
        <ListItem />
      </List>
    </Card>
  );

  const customRightList = (title, items) => (
    <Card
      style={{
        display: "block",
        transitionDuration: "0.3s",
        height: "60vh",
      }}
    >
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {right !== ""
          ? right.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${value.First_Name} ${value.last_Name}`}
                  />
                </ListItem>
              );
            })
          : null}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <div style={{ minHeight: "75vh" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>person</Icon>
        <h2 style={{ marginLeft: 5 }}>Change Supervisor</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Employee Profile</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Change Supervisor</p>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          autoFocus
          onClick={submitReplaceHandler}
          style={{ marginRight: 10 }}
        >
          Merge
        </Button>
      </div>

      <div style={{ height: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={5} style={{ padding: 50 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-age-native-simple">
                Select A Supervisor
              </InputLabel>
              <Select
                native
                value={old_supervisor_id}
                onChange={(e) => setOldSupervisor(e.target.value)}
                label="Select A Supervisor"
                inputProps={{
                  name: "Select A Supervisor",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                {employees
                  ? employees.map((item) => (
                      <option value={item.id}>
                        {item.First_Name} {item.Last_Name}
                      </option>
                    ))
                  : null}
              </Select>
            </FormControl>
            <div>{left !== "" ? customLeftList("Choices", left) : null}</div>

            {/* <div style={{ marginTop: 50, textAlign: "right" }}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                autoFocus
                onClick={submitClearHandler}
                style={{ marginRight: 20 }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                autoFocus
                onClick={submitReplaceHandler}
                style={{ marginRight: 10 }}
              >
                Replace
              </Button>
            </div> */}
          </Grid>
          <Grid item xs={2} style={{ padding: 50 }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ marginTop: "30vh" }}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5} style={{ padding: 50 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-age-native-simple">
                Select A New Supervisor
              </InputLabel>
              <Select
                native
                value={new_supervisor_id}
                onChange={(e) => setNewSupervisor(e.target.value)}
                label="Select A New Supervisor"
                inputProps={{
                  name: "Select A New Supervisor",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                {employees
                  ? employees.map((item) => (
                      <option value={item.id}>
                        {item.First_Name} {item.Last_Name}
                      </option>
                    ))
                  : null}
              </Select>
            </FormControl>
            {right !== "" ? customRightList("Chosen", right) : null}
          </Grid>
        </Grid>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default Body;
