import axios from "axios";
import {
  KPI_MAPPINGS_REQUEST,
  KPI_MAPPINGS_SUCCESS,
  KPI_MAPPINGS_FAIL,
  KPI_MAPPINGS_CREATE_REQUEST,
  KPI_MAPPINGS_CREATE_SUCCESS,
  KPI_MAPPINGS_CREATE_FAIL,
  KPI_MAPPINGS_UPDATE_REQUEST,
  KPI_MAPPINGS_UPDATE_SUCCESS,
  KPI_MAPPINGS_UPDATE_FAIL,
  KPI_MAPPINGS_DELETE_REQUEST,
  KPI_MAPPINGS_DELETE_SUCCESS,
  KPI_MAPPINGS_DELETE_FAIL,
  KPI_MAPPINGS_PERMISSION_REQUEST,
  KPI_MAPPINGS_PERMISSION_SUCCESS,
  KPI_MAPPINGS_PERMISSION_FAIL,
} from "../constants/kpimappingsConstant";

export const kpimappingsList = () => async (dispatch) => {
  try {
    dispatch({
      type: KPI_MAPPINGS_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/kpimappings"
    );

    dispatch({
      type: KPI_MAPPINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_MAPPINGS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const kpimappingsCreateAction = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_MAPPINGS_CREATE_REQUEST,
    });

    const config = {
      headers: {
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      "http://kpi.clonestudiobd.com/api/kpimappings",
      postData,
      config
    );

    console.log("logggg", postData);

    dispatch({
      type: KPI_MAPPINGS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_MAPPINGS_CREATE_FAIL,
      payload:
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.errors,
    });
  }
};

export const kpimappingsUpdateAction = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_MAPPINGS_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      "http://kpi.clonestudiobd.com/api/kpimappings/update",
      postData,
      config
    );

    console.log("posssst", postData);

    dispatch({
      type: KPI_MAPPINGS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_MAPPINGS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.errors,
    });
  }
};

export const kpimappingsDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_MAPPINGS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/kpimappings/${id}`,
      config
    );

    dispatch({
      type: KPI_MAPPINGS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: KPI_MAPPINGS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const departmentPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: DEPARTMENT_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: DEPARTMENT_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: DEPARTMENT_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
