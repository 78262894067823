import {
  EMPLOYEES_REQUEST,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_FAIL,
  EMPLOYEES_CREATE_REQUEST,
  EMPLOYEES_CREATE_SUCCESS,
  EMPLOYEES_CREATE_FAIL,
  EMPLOYEES_CREATE_RESET,
  EMPLOYEES_UPDATE_REQUEST,
  EMPLOYEES_UPDATE_SUCCESS,
  EMPLOYEES_UPDATE_FAIL,
  EMPLOYEES_UPDATE_RESET,
  EMPLOYEES_DELETE_REQUEST,
  EMPLOYEES_DELETE_SUCCESS,
  EMPLOYEES_DELETE_FAIL,
  EMPLOYEES_PERMISSION_REQUEST,
  EMPLOYEES_PERMISSION_SUCCESS,
  EMPLOYEES_PERMISSION_FAIL,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAIL,
  SUB_SECTION_REQUEST,
  SUB_SECTION_SUCCESS,
  SUB_SECTION_FAIL,
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
  EMPLOYEE_CATEGORIES_REQUEST,
  EMPLOYEE_CATEGORIES_SUCCESS,
  EMPLOYEE_CATEGORIES_FAIL,
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,
} from "../constants/employeeConstant";

export const employeeReducer = (state = { employees: [] }, action) => {
  switch (action.type) {
    case EMPLOYEES_REQUEST:
      return { loading: true, employees: [] };
    case EMPLOYEES_SUCCESS:
      return { loading: false, employees: action.payload };
    case EMPLOYEES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEES_CREATE_REQUEST:
      return { loading: true };
    case EMPLOYEES_CREATE_SUCCESS:
      return { loading: false, employeeCreate: action.payload };
    case EMPLOYEES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const employeeUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEES_UPDATE_REQUEST:
      return { loading: true };
    case EMPLOYEES_UPDATE_SUCCESS:
      return { loading: false, employeeUpdate: action.payload };
    case EMPLOYEES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEES_UPDATE_RESET:
      return { employeeCreate: {} };
    default:
      return state;
  }
};

export const employeeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEES_DELETE_REQUEST:
      return { loading: true };
    case EMPLOYEES_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EMPLOYEES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//org
export const organizationReducer = (state = { organizations: [] }, action) => {
  switch (action.type) {
    case ORGANIZATION_REQUEST:
      return { loading: true, organizations: [] };
    case ORGANIZATION_SUCCESS:
      return { loading: false, organizations: action.payload };
    case ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//sub-sec
export const subSectionReducer = (state = { subSections: [] }, action) => {
  switch (action.type) {
    case SUB_SECTION_REQUEST:
      return { loading: true, subSections: [] };
    case SUB_SECTION_SUCCESS:
      return { loading: false, subSections: action.payload };
    case SUB_SECTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//desig
export const designationReducer = (state = { designations: [] }, action) => {
  switch (action.type) {
    case DESIGNATIONS_REQUEST:
      return { loading: true, designations: [] };
    case DESIGNATIONS_SUCCESS:
      return { loading: false, designations: action.payload };
    case DESIGNATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//e-catagory

export const categoryReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_REQUEST:
      return { loading: true, categories: [] };
    case EMPLOYEE_CATEGORIES_SUCCESS:
      return { loading: false, categories: action.payload };
    case EMPLOYEE_CATEGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//department

export const departmentReducer = (state = { departments: [] }, action) => {
  switch (action.type) {
    case DEPARTMENT_REQUEST:
      return { loading: true, departments: [] };
    case DEPARTMENT_SUCCESS:
      return { loading: false, departments: action.payload };
    case DEPARTMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const dimentionsPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case EMPLOYEES_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case EMPLOYEES_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case EMPLOYEES_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
