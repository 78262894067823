import {
  BALANCE_DIMENTIONS_REQUEST,
  BALANCE_DIMENTIONS_SUCCESS,
  BALANCE_DIMENTIONS_FAIL,
  BALANCE_DIMENTIONS_CREATE_REQUEST,
  BALANCE_DIMENTIONS_CREATE_SUCCESS,
  BALANCE_DIMENTIONS_CREATE_FAIL,
  BALANCE_DIMENTIONS_CREATE_RESET,
  BALANCE_DIMENTIONS_UPDATE_REQUEST,
  BALANCE_DIMENTIONS_UPDATE_SUCCESS,
  BALANCE_DIMENTIONS_UPDATE_FAIL,
  BALANCE_DIMENTIONS_UPDATE_RESET,
  BALANCE_DIMENTIONS_DELETE_REQUEST,
  BALANCE_DIMENTIONS_DELETE_SUCCESS,
  BALANCE_DIMENTIONS_DELETE_FAIL,
  BALANCE_DIMENTIONS_PERMISSION_REQUEST,
  BALANCE_DIMENTIONS_PERMISSION_SUCCESS,
  BALANCE_DIMENTIONS_PERMISSION_FAIL,
} from "../constants/dimentionsConstant";

export const dimentionsReducer = (state = { dimentions: [] }, action) => {
  switch (action.type) {
    case BALANCE_DIMENTIONS_REQUEST:
      return { loading: true, dimentions: [] };
    case BALANCE_DIMENTIONS_SUCCESS:
      return { loading: false, dimentions: action.payload };
    case BALANCE_DIMENTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dimentionsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BALANCE_DIMENTIONS_CREATE_REQUEST:
      return { loading: true };
    case BALANCE_DIMENTIONS_CREATE_SUCCESS:
      return { loading: false, dimentionsCreate: action.payload };
    case BALANCE_DIMENTIONS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BALANCE_DIMENTIONS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const dimentionsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BALANCE_DIMENTIONS_UPDATE_REQUEST:
      return { loading: true };
    case BALANCE_DIMENTIONS_UPDATE_SUCCESS:
      return { loading: false, dimentionsUpdate: action.payload };
    case BALANCE_DIMENTIONS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BALANCE_DIMENTIONS_UPDATE_RESET:
      return { dimentionsCreate: {} };
    default:
      return state;
  }
};

export const dimentionsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BALANCE_DIMENTIONS_DELETE_REQUEST:
      return { loading: true };
    case BALANCE_DIMENTIONS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BALANCE_DIMENTIONS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const dimentionsPermissionReducer = (
//   state = { departmentPermission: [] },
//   action
// ) => {
//   switch (action.type) {
//     case BALANCE_DIMENTIONS_PERMISSION_REQUEST:
//       return { loading: true, departmentPermission: [] };
//     case BALANCE_DIMENTIONS_PERMISSION_SUCCESS:
//       return { loading: false, departmentPermission: action.payload };
//     case BALANCE_DIMENTIONS_PERMISSION_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };
