import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import EmployeeAddTable from "./EmployeeAddTable";
import InputForm from "./InputForm";
import { subSectionList } from "../../../redux/actions/subSectionActions";
import { departmentList } from "../../../redux/actions/departmentAction";
import { designationList } from "../../../redux/actions/designationAction";

import { useDispatch, useSelector } from "react-redux";
import {
  employeeKpisCreateAction,
  employeeKpisList,
  employeeWithKpiList,
} from "../../../redux/actions/employeeKpisActions";
import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MenuBookIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    dimentions,
    setNotify,
    kpiMapping,
    employee,
    kpis,
    tableData,
    kpi_id,
  } = props;

  const dispatch = useDispatch();
  const [filteredKpi, setFilteredKpi] = React.useState("");

  const [postData, setPostData] = useState({
    data: "",
  });

  console.log("yyyyy", kpis);
  console.log("yyy2", kpi_id);

  const employeeWithKpi = useSelector((state) => state.employeeWithKpi);

  const { employeeWithKpis } = employeeWithKpi;

  const subSection = useSelector((state) => state.subSection);

  const { subSections } = subSection;

  const department = useSelector((state) => state.department);

  const { departments } = department;

  const designation = useSelector((state) => state.designation);

  const { designations } = designation;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(employeeKpisCreateAction(JSON.stringify(postData)));
    setFilteredKpi("");
    setOpenPopup(false);
    setTimeout(() => {
      dispatch(employeeKpisList());
      dispatch(employeeWithKpiList(kpi_id));
    }, 500);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    dispatch(employeeWithKpiList(kpi_id));
    dispatch(subSectionList());
    dispatch(departmentList());
    dispatch(designationList());
  }, [kpi_id]);

  console.log("post data", postData);
  console.log("employeeWithKpis", employeeWithKpis);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Employees
      </DialogTitle>
      <DialogContent dividers>
        <EmployeeAddTable
          kpiList={employeeWithKpis}
          style={{ width: 1200 }}
          setPostData={setPostData}
          employee_id={kpi_id}
          tableData={tableData}
          kpis={kpis}
          subSections={subSections}
          departments={departments}
          designations={designations}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
