import axios from "axios";
import {
  EMPLOYEES_REQUEST,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_FAIL,
  EMPLOYEES_CREATE_REQUEST,
  EMPLOYEES_CREATE_SUCCESS,
  EMPLOYEES_CREATE_FAIL,
  EMPLOYEES_CREATE_RESET,
  EMPLOYEES_UPDATE_REQUEST,
  EMPLOYEES_UPDATE_SUCCESS,
  EMPLOYEES_UPDATE_FAIL,
  EMPLOYEES_UPDATE_RESET,
  EMPLOYEES_DELETE_REQUEST,
  EMPLOYEES_DELETE_SUCCESS,
  EMPLOYEES_DELETE_FAIL,
  EMPLOYEES_PERMISSION_REQUEST,
  EMPLOYEES_PERMISSION_SUCCESS,
  EMPLOYEES_PERMISSION_FAIL,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAIL,
  SUB_SECTION_REQUEST,
  SUB_SECTION_SUCCESS,
  SUB_SECTION_FAIL,
  DESIGNATIONS_REQUEST,
  DESIGNATIONS_SUCCESS,
  DESIGNATIONS_FAIL,
  EMPLOYEE_CATEGORIES_REQUEST,
  EMPLOYEE_CATEGORIES_SUCCESS,
  EMPLOYEE_CATEGORIES_FAIL,
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,
} from "../constants/employeeConstant";

export const employeesList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEES_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/employees"
    );

    dispatch({
      type: EMPLOYEES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const employeesCreateAction =
  (
    First_Name,
    last_name,
    org_ref_id,
    birth_date,
    join_date,
    phone_no,
    email_address,
    organization_id,
    sub_section_id,
    designation_id,
    employee_category_id,
    supervisor_id,
    department_id,
    approval_panel_id,
    same_as
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/employees",
        {
          First_Name,
          last_name,
          org_ref_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          organization_id,
          sub_section_id,
          designation_id,
          employee_category_id,
          supervisor_id,
          department_id,
          approval_panel_id,
          same_as,
        },
        config
      );

      dispatch({
        type: EMPLOYEES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEES_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const employeesUpdateAction =
  (
    id,
    First_Name,
    last_name,
    org_ref_id,
    birth_date,
    join_date,
    phone_no,
    email_address,
    organization_id,
    sub_section_id,
    designation_id,
    employee_category_id,
    supervisor_id,
    department_id,
    approval_panel_id,
    same_as
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEES_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://kpi.clonestudiobd.com/api/employees/${id}`,
        {
          First_Name,
          last_name,
          org_ref_id,
          birth_date,
          join_date,
          phone_no,
          email_address,
          organization_id,
          sub_section_id,
          designation_id,
          employee_category_id,
          supervisor_id,
          department_id,
          approval_panel_id,
          same_as,
        },
        config
      );

      dispatch({
        type: EMPLOYEES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const employeesDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEES_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/employees/${id}`,
      config
    );

    dispatch({
      type: EMPLOYEES_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEES_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//ORGANIZATION
export const organizationList = () => async (dispatch) => {
  try {
    dispatch({
      type: ORGANIZATION_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/organizations"
    );

    dispatch({
      type: ORGANIZATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORGANIZATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//SUBSECTIONS
export const subSectionList = () => async (dispatch) => {
  try {
    dispatch({
      type: SUB_SECTION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/subsections",
      config
    );

    dispatch({
      type: SUB_SECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUB_SECTION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//designations
export const designationList = () => async (dispatch) => {
  try {
    dispatch({
      type: DESIGNATIONS_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/designations"
    );

    dispatch({
      type: DESIGNATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DESIGNATIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//e-catagory

export const categoryList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CATEGORIES_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/employeecategories"
    );

    dispatch({
      type: EMPLOYEE_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CATEGORIES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

//department

export const departmentList = () => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/departments"
    );

    dispatch({
      type: DEPARTMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: EMPLOYEES_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EMPLOYEES_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
