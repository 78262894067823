export const APPROVAL_PANEL_REQUEST = "APPROVAL_PANEL_REQUEST";
export const APPROVAL_PANEL_SUCCESS = "APPROVAL_PANEL_SUCCESS";
export const APPROVAL_PANEL_FAIL = "APPROVAL_PANEL_FAIL";

export const APPROVAL_PANEL_CREATE_REQUEST = "APPROVAL_PANEL_CREATE_REQUEST";
export const APPROVAL_PANEL_CREATE_SUCCESS = "APPROVAL_PANEL_CREATE_SUCCESS";
export const APPROVAL_PANEL_CREATE_FAIL = "APPROVAL_PANEL_CREATE_FAIL";
export const APPROVAL_PANEL_CREATE_RESET = "APPROVAL_PANEL_CREATE_RESET";

export const APPROVAL_PANEL_UPDATE_REQUEST = "APPROVAL_PANEL_UPDATE_REQUEST";
export const APPROVAL_PANEL_UPDATE_SUCCESS = "APPROVAL_PANEL_UPDATE_SUCCESS";
export const APPROVAL_PANEL_UPDATE_FAIL = "APPROVAL_PANEL_UPDATE_FAIL";
export const APPROVAL_PANEL_UPDATE_RESET = "APPROVAL_PANEL_UPDATE_RESET";

export const APPROVAL_PANEL_DELETE_REQUEST = "APPROVAL_PANEL_DELETE_REQUEST";
export const APPROVAL_PANEL_DELETE_SUCCESS = "APPROVAL_PANEL_DELETE_SUCCESS";
export const APPROVAL_PANEL_DELETE_FAIL = "APPROVAL_PANEL_DELETE_FAIL";

export const APPROVAL_PANEL_PERMISSION_REQUEST =
  "APPROVAL_PANEL_PERMISSION_REQUEST";
export const APPROVAL_PANEL_PERMISSION_SUCCESS =
  "APPROVAL_PANEL_PERMISSION_SUCCESS";
export const APPROVAL_PANEL_PERMISSION_FAIL = "APPROVAL_PANEL_PERMISSION_FAIL";
