import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ApprovalTable from "./ApprovalTable";
import { useDispatch, useSelector } from "react-redux";
import { dimentionsList } from "../redux/actions/dimentionsAction";
import { approvalList } from "../redux/actions/approvalAction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ minWidth: "65vw" }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: "70wh",
    marginTop: 20,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dimention = useSelector((state) => state.dimention);
  const { dimentions } = dimention;

  const approval = useSelector((state) => state.approval);
  const { approvals } = approval;

  console.log("approval", approvals);

  const user_id = "user-1";

  useEffect(() => {
    dispatch(dimentionsList());
    dispatch(approvalList(user_id));
  }, [dispatch]);

  const approvalPending = approvals
    ? approvals
        .filter((item) => {
          return item.approval_status === "N";
        })
        .map((item) => item)
    : null;

  console.log("approval", approvalPending);

  const approvalAccepted = approvals
    ? approvals
        .filter((item) => {
          return item.approval_status === "Y";
        })
        .map((item) => item)
    : null;

  const approvalDenied = approvals
    ? approvals
        .filter((item) => {
          return item.approval_status === "C";
        })
        .map((item) => item)
    : null;

  return (
    <div>
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Icon style={{ fontSize: 25, marginTop: 18 }}>approval</Icon>
          <h2 style={{ marginLeft: 5 }}>Admin Approvals</h2>
        </div>
      </div>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Approved" {...a11yProps(1)} />
          <Tab label="Deny" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ApprovalTable approvalPending={approvalPending} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ApprovalTable approvalPending={approvalAccepted} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ApprovalTable approvalPending={approvalDenied} />
        </TabPanel>
      </div>
    </div>
  );
}
