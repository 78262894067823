import axios from "axios";
import {
  EMPLOYEE_KPIS_REQUEST,
  EMPLOYEE_KPIS_SUCCESS,
  EMPLOYEE_KPIS_FAIL,
  EMPLOYEE_KPIS_CREATE_REQUEST,
  EMPLOYEE_KPIS_CREATE_SUCCESS,
  EMPLOYEE_KPIS_CREATE_FAIL,
  EMPLOYEE_KPIS_UPDATE_REQUEST,
  EMPLOYEE_KPIS_UPDATE_SUCCESS,
  EMPLOYEE_KPIS_UPDATE_FAIL,
  EMPLOYEE_KPIS_DELETE_REQUEST,
  EMPLOYEE_KPIS_DELETE_SUCCESS,
  EMPLOYEE_KPIS_DELETE_FAIL,
  EMPLOYEE_KPIS_WITH_ID_REQUEST,
  EMPLOYEE_KPIS_WITH_ID_SUCCESS,
  EMPLOYEE_KPIS_WITH_ID_FAIL,
  EMPLOYEE_WITH_KPI_REQUEST,
  EMPLOYEE_WITH_KPI_SUCCESS,
  EMPLOYEE_WITH_KPI_FAIL,
} from "../constants/employeeKpisConstants";

export const employeeKpisList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_KPIS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/employeekpis",
      config
    );

    dispatch({
      type: EMPLOYEE_KPIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_KPIS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const employeeKpisWithIdList = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_KPIS_WITH_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    const { data } = await axios.get(
      `http://kpi.clonestudiobd.com/api/kpilistwithid/${employee_id}`,
      config
    );

    dispatch({
      type: EMPLOYEE_KPIS_WITH_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_KPIS_WITH_ID_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const employeeWithKpiList = (kpi_id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_WITH_KPI_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      `http://kpi.clonestudiobd.com/api/employeewithkpi/${kpi_id}`,
      config
    );

    dispatch({
      type: EMPLOYEE_WITH_KPI_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_WITH_KPI_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const employeeKpisCreateAction = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_KPIS_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("data4", postData);

    const { data } = await axios
      .post(
        "http://kpi.clonestudiobd.com/api/employeeKpiBulk",
        postData,
        config
      )
      .then((res) => console.log(res.data));

    dispatch({
      type: EMPLOYEE_KPIS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_KPIS_CREATE_FAIL,
      payload:
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.errors,
    });
  }
};

export const employeeKpisUpdateAction = (updateData) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_KPIS_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      "http://kpi.clonestudiobd.com/api/employeeKpiBulkUpdate",
      updateData,
      config
    );

    dispatch({
      type: EMPLOYEE_KPIS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_KPIS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.errors,
    });
  }
};

export const employeeKpisDeleteAction = (id, setNotify) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_KPIS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios
      .delete(`http://kpi.clonestudiobd.com/api/employeekpis/${id}`, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("abcd", error.response);
      });

    console.log("ooo", data);

    dispatch({
      type: EMPLOYEE_KPIS_DELETE_SUCCESS,
    });
  } catch (response) {
    dispatch({
      type: EMPLOYEE_KPIS_DELETE_FAIL,
      payload: response ? response.data : response,
    });
  }
};
