import axios from "axios";
import {
  KPI_OBJECTIVES_REQUEST,
  KPI_OBJECTIVES_SUCCESS,
  KPI_OBJECTIVES_FAIL,
  KPI_OBJECTIVES_CREATE_REQUEST,
  KPI_OBJECTIVES_CREATE_SUCCESS,
  KPI_OBJECTIVES_CREATE_FAIL,
  KPI_OBJECTIVES_UPDATE_REQUEST,
  KPI_OBJECTIVES_UPDATE_SUCCESS,
  KPI_OBJECTIVES_UPDATE_FAIL,
  KPI_OBJECTIVES_DELETE_REQUEST,
  KPI_OBJECTIVES_DELETE_SUCCESS,
  KPI_OBJECTIVES_DELETE_FAIL,
  KPI_OBJECTIVES_PERMISSION_REQUEST,
  KPI_OBJECTIVES_PERMISSION_SUCCESS,
  KPI_OBJECTIVES_PERMISSION_FAIL,
} from "../constants/objectivesConstant";

export const objectiveList = () => async (dispatch) => {
  try {
    dispatch({
      type: KPI_OBJECTIVES_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/kpiobjectives"
    );

    dispatch({
      type: KPI_OBJECTIVES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_OBJECTIVES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const objectiveCreateAction =
  (objective,description) =>
  async (dispatch) => {
    try {
      dispatch({
        type: KPI_OBJECTIVES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpiobjectives",
        {
          objective,
          description
          
        },
        config
      );

      console.log(
        "data4",
        objective,
        description
      );

      dispatch({
        type: KPI_OBJECTIVES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPI_OBJECTIVES_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const objectiveUpdateAction =
  (
    id,
    objective,
    description
   
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: KPI_OBJECTIVES_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/kpiobjectives/${id}`,
        {
          objective,
          description
        },
        config
      );

      console.log(
        "update data",
        objective,
        description
      );

      dispatch({
        type: KPI_OBJECTIVES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPI_OBJECTIVES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const objectiveDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_OBJECTIVES_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/kpiobjectives/${id}`,
      config
    );

    dispatch({
      type: KPI_OBJECTIVES_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: KPI_OBJECTIVES_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const departmentPermissionAction =
  (user_role_id, app_menu_id) => async (dispatch) => {
    try {
      dispatch({
        type: KPI_OBJECTIVES_PERMISSION_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      console.log("DataCheck", user_role_id, app_menu_id);

      const { data } = await axios.post(
        "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
        {
          user_role_id: user_role_id,
          app_menu_id: app_menu_id,
        },
        config
      );

      console.log("DataP", user_role_id, app_menu_id);

      dispatch({
        type: KPI_OBJECTIVES_PERMISSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPI_OBJECTIVES_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
