import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import {
  roleFunctionList,
  userRoleList,
} from "../../../redux/actions/userRoleActions";
import { permissionAction } from "../../../redux/actions/permissionActions";
import { moduleGlobalAction } from "../../../redux/actions/moduleActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const roleFunction = useSelector((state) => state.roleFunction);
  const [user_role_id, setUserRoleId] = useState("");
  const [app_menu_id, setAppMenuId] = useState(52);

  const { roleFunctions } = roleFunction;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission", permissions);

  const role = useSelector((state) => state.role);

  const { roles } = role;

  const moduleGlobal = useSelector((state) => state.moduleGlobal);

  const { globalModules } = moduleGlobal;

  console.log("111user", userData);

  useEffect(() => {
    dispatch(userRoleList());
    dispatch(moduleGlobalAction());
    dispatch(roleFunctionList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  console.log("wer", roleFunctions);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>settings</Icon>
        <h2 style={{ marginLeft: 5 }}>User Role Functions</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
        <p> User Role Functions</p>
      </div>
      <div style={{ marginTop: 20 }}>
        <Table
          roleFunctions={roleFunctions}
          modules={globalModules}
          roles={roles}
          permissions={permissions}
        />
      </div>
    </div>
  );
};

export default Body;
