import axios from "axios";
import {
  KPIS_REQUEST,
  KPIS_SUCCESS,
  KPIS_FAIL,
  KPIS_CREATE_REQUEST,
  KPIS_CREATE_SUCCESS,
  KPIS_CREATE_FAIL,
  KPIS_UPDATE_REQUEST,
  KPIS_UPDATE_SUCCESS,
  KPIS_UPDATE_FAIL,
  KPIS_DELETE_REQUEST,
  KPIS_DELETE_SUCCESS,
  KPIS_DELETE_FAIL,
} from "../constants/kpisConstant";

export const kpisList = () => async (dispatch) => {
  try {
    dispatch({
      type: KPIS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/kpis",
      config
    );

    dispatch({
      type: KPIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPIS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const kpisCreateAction =
  (
    kpi,
    objective,
    balance_dimention_id,
    frequency,
    direction,
    value_format,
    counting
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: KPIS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpis",
        {
          kpi,
          objective,
          balance_dimention_id,
          frequency,
          direction,
          value_format,
          counting,
        },
        config
      );

      dispatch({
        type: KPIS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPIS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const kpisUpdateAction =
  (
    id,
    kpi,
    objective,
    balance_dimention_id,
    frequency,
    direction,
    value_format,
    counting
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: KPIS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/kpis/${id}`,
        {
          id,
          kpi,
          objective,
          balance_dimention_id,
          frequency,
          direction,
          value_format,
          counting,
        },
        config
      );

      // console.log(
      //   "update data",
      //   id,
      //   KPIS_name,
      //   KPIS_alias,
      //   department_id,
      //   print_serial
      // );

      dispatch({
        type: KPIS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPIS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const kpisDeleteAction = (id, setNotify) => async (dispatch) => {
  try {
    dispatch({
      type: KPIS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios
      .delete(`http://kpi.clonestudiobd.com/api/kpis/${id}`, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("abcd", error.response);
      });

    console.log("ooo", data);

    dispatch({
      type: KPIS_DELETE_SUCCESS,
    });
  } catch (response) {
    dispatch({
      type: KPIS_DELETE_FAIL,
      payload: response ? response.data : response,
    });
  }
};
