export const APPROVAL_LIST_REQUEST = "APPROVAL_LIST_REQUEST";
export const APPROVAL_LIST_SUCCESS = "APPROVAL_LIST_SUCCESS";
export const APPROVAL_LIST_FAIL = "APPROVAL_LIST_FAIL";

export const APPROVAL_ENTRY_LIST_REQUEST = "APPROVAL_ENTRY_LIST_REQUEST";
export const APPROVAL_ENTRY_LIST_SUCCESS = "APPROVAL_ENTRY_LIST_SUCCESS";
export const APPROVAL_ENTRY_LIST_FAIL = "APPROVAL_ENTRY_LIST_FAIL";

export const APPROVAL_FORWARD_REQUEST = "APPROVAL_FORWARD_REQUEST";
export const APPROVAL_FORWARD_SUCCESS = "APPROVAL_FORWARD_SUCCESS";
export const APPROVAL_FORWARD_FAIL = "APPROVAL_FORWARD_FAIL";

export const APPROVAL_CREATE_REQUEST = "APPROVAL_CREATE_REQUEST";
export const APPROVAL_CREATE_SUCCESS = "APPROVAL_CREATE_SUCCESS";
export const APPROVAL_CREATE_FAIL = "APPROVAL_CREATE_FAIL";

export const APPROVAL_MODIFY_REQUEST = "APPROVAL_MODIFY_REQUEST";
export const APPROVAL_MODIFY_SUCCESS = "APPROVAL_MODIFY_SUCCESS";
export const APPROVAL_MODIFY_FAIL = "APPROVAL_MODIFY_FAIL";

export const APPROVAL_FORWARD_NEXT_REQUEST = "APPROVAL_FORWARD_NEXT_REQUEST";
export const APPROVAL_FORWARD_NEXT_SUCCESS = "APPROVAL_FORWARD_NEXT_SUCCESS";
export const APPROVAL_FORWARD_NEXT_FAIL = "APPROVAL_FORWARD_NEXT_FAIL";
