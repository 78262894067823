import {
  KPI_OBJECTIVES_REQUEST,
  KPI_OBJECTIVES_SUCCESS,
  KPI_OBJECTIVES_FAIL,
  KPI_OBJECTIVES_CREATE_REQUEST,
  KPI_OBJECTIVES_CREATE_SUCCESS,
  KPI_OBJECTIVES_CREATE_FAIL,
  KPI_OBJECTIVES_CREATE_RESET,
  KPI_OBJECTIVES_UPDATE_REQUEST,
  KPI_OBJECTIVES_UPDATE_SUCCESS,
  KPI_OBJECTIVES_UPDATE_FAIL,
  KPI_OBJECTIVES_UPDATE_RESET,
  KPI_OBJECTIVES_DELETE_REQUEST,
  KPI_OBJECTIVES_DELETE_SUCCESS,
  KPI_OBJECTIVES_DELETE_FAIL,
  KPI_OBJECTIVES_PERMISSION_REQUEST,
  KPI_OBJECTIVES_PERMISSION_SUCCESS,
  KPI_OBJECTIVES_PERMISSION_FAIL,
} from "../constants/objectivesConstant";

export const objectiveReducer = (state = { objectives: [] }, action) => {
  switch (action.type) {
    case KPI_OBJECTIVES_REQUEST:
      return { loading: true, objectives: [] };
    case KPI_OBJECTIVES_SUCCESS:
      return { loading: false, objectives: action.payload };
    case KPI_OBJECTIVES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const objectiveCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_OBJECTIVES_CREATE_REQUEST:
      return { loading: true };
    case KPI_OBJECTIVES_CREATE_SUCCESS:
      return { loading: false, objectiveCreates: action.payload };
    case KPI_OBJECTIVES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_OBJECTIVES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const objectiveUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_OBJECTIVES_UPDATE_REQUEST:
      return { loading: true };
    case KPI_OBJECTIVES_UPDATE_SUCCESS:
      return { loading: false, objectiveUpdates: action.payload };
    case KPI_OBJECTIVES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_OBJECTIVES_UPDATE_RESET:
      return { departmentCreates: {} };
    default:
      return state;
  }
};

export const objectiveDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_OBJECTIVES_DELETE_REQUEST:
      return { loading: true };
    case KPI_OBJECTIVES_DELETE_SUCCESS:
      return { loading: false, success: true };
    case KPI_OBJECTIVES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const departmentPermissionReducer = (
  state = { departmentPermission: [] },
  action
) => {
  switch (action.type) {
    case KPI_OBJECTIVES_PERMISSION_REQUEST:
      return { loading: true, departmentPermission: [] };
    case KPI_OBJECTIVES_PERMISSION_SUCCESS:
      return { loading: false, departmentPermission: action.payload };
    case KPI_OBJECTIVES_PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
