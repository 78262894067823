import React from "react";
import "./App.css";
import Layout from "./components/layout/Layout";
import Login from "./components/pages/Login";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import PrivateRoute from "./components/route/PrivateRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <PrivateRoute path="/app" component={Layout} />
        <Redirect exact from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
