import {
  APPROVAL_PANEL_MEMBERS_REQUEST,
  APPROVAL_PANEL_MEMBERS_SUCCESS,
  APPROVAL_PANEL_MEMBERS_FAIL,
  APPROVAL_PANEL_MEMBERS_CREATE_REQUEST,
  APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_CREATE_FAIL,
  APPROVAL_PANEL_MEMBERS_CREATE_RESET,
  APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST,
  APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_UPDATE_FAIL,
  APPROVAL_PANEL_MEMBERS_DELETE_REQUEST,
  APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_DELETE_FAIL,
} from "../constants/approvalPanelMembersConstant";

export const approvalPanelMemberReducer = (
  state = { approvalPanelMembers: [] },
  action
) => {
  switch (action.type) {
    case APPROVAL_PANEL_MEMBERS_REQUEST:
      return { loading: true, approvalPanelMembers: [] };
    case APPROVAL_PANEL_MEMBERS_SUCCESS:
      return { loading: false, approvalPanelMembers: action.payload };
    case APPROVAL_PANEL_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalPanelMemberCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_MEMBERS_CREATE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS:
      return { loading: false, approvalPanelMembersCreate: action.payload };
    case APPROVAL_PANEL_MEMBERS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case APPROVAL_PANEL_MEMBERS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const approvalPanelMemberUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS:
      return { loading: false, approvalPanelMemberUpdate: action.payload };
    case APPROVAL_PANEL_MEMBERS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalPanelMemberDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_MEMBERS_DELETE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case APPROVAL_PANEL_MEMBERS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
