import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { dimentionsList } from "../../../redux/actions/dimentionsAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
    const [app_menu_id, setAppMenuId] = useState(53);

  const dimention = useSelector((state) => state.dimention);

  const { dimentions } = dimention;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", permissions);
  console.log(userData);

  useEffect(() => {
    dispatch(dimentionsList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>timeline</Icon>
        <h2 style={{ marginLeft: 5 }}>Balance Dimention</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>My KPI</p>
      
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Balance Dimention</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table dimentions={dimentions} permissions={permissions}/>
      </div>
    </div>
  );
};

export default Body;
