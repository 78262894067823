import {
  SCORE_CARD_MONTH_REQUEST,
  SCORE_CARD_MONTH_SUCCESS,
  SCORE_CARD_MONTH_FAIL,
} from "../constants/scoreCardConstant";

export const scoreCardMonthReducer = (state = { months: [] }, action) => {
  switch (action.type) {
    case SCORE_CARD_MONTH_REQUEST:
      return { loading: true, months: [] };
    case SCORE_CARD_MONTH_SUCCESS:
      return { loading: false, months: action.payload };
    case SCORE_CARD_MONTH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
