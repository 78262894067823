import {
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAIL,
  ORGANIZATION_CREATE_REQUEST,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_CREATE_FAIL,
  ORGANIZATION_CREATE_RESET,
  ORGANIZATION_UPDATE_REQUEST,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_UPDATE_FAIL,
  ORGANIZATION_UPDATE_RESET,
  ORGANIZATION_DELETE_REQUEST,
  ORGANIZATION_DELETE_SUCCESS,
  ORGANIZATION_DELETE_FAIL,
  ORGANIZATION_PERMISSION_REQUEST,
  ORGANIZATION_PERMISSION_SUCCESS,
  ORGANIZATION_PERMISSION_FAIL,
} from "../constants/organizationConstant";

export const organizationReducer = (state = { organizations: [] }, action) => {
  switch (action.type) {
    case ORGANIZATION_REQUEST:
      return { loading: true, organizations: [] };
    case ORGANIZATION_SUCCESS:
      return { loading: false, organizations: action.payload };
    case ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const organizationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATION_CREATE_REQUEST:
      return { loading: true };
    case ORGANIZATION_CREATE_SUCCESS:
      return { loading: false, organizationCreates: action.payload };
    case ORGANIZATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORGANIZATION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const organizationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATION_UPDATE_REQUEST:
      return { loading: true };
    case ORGANIZATION_UPDATE_SUCCESS:
      return { loading: false, organizationUpdates: action.payload };
    case ORGANIZATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ORGANIZATION_UPDATE_RESET:
      return { organizationCreates: {} };
    default:
      return state;
  }
};

export const organizationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANIZATION_DELETE_REQUEST:
      return { loading: true };
    case ORGANIZATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ORGANIZATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const organizationPermissionReducer = (
  state = { organizationPermission: [] },
  action
) => {
  switch (action.type) {
    case ORGANIZATION_PERMISSION_REQUEST:
      return { loading: true, organizationPermission: [] };
    case ORGANIZATION_PERMISSION_SUCCESS:
      return { loading: false, organizationPermission: action.payload };
    case ORGANIZATION_PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
