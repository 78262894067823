import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { kpisUpdateAction, kpisList } from "../../../redux/actions/kpisAction";
import { EditValidation } from "./FormValidation";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MenuBookIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openEditPopup, setOpenEditPopup, item, dimentions, setNotify } =
    props;
  const [last_name, setLastName] = React.useState("");
  const [user_role_id, setRoleId] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [direction, setDirection] = React.useState("");
  const [value_format, setValueFormat] = React.useState("");
  const [counting, setCounting] = React.useState("");
  const [employee_id, setEmployeeId] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [user_status, setUserStatus] = React.useState("");
  const [sign_photo, setSignPhoto] = React.useState("");
  const id = item.id;

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setRoleId(item.department_id);
    setFrequency(item.frequency);
    setDirection(item.direction);
    setValueFormat(item.value_format);
    setCounting(item.counting);
    // setUserStatus(item.published_status);
  }, [item]);

  const dispatch = useDispatch();
  const roleUpdate = useSelector((state) => state.roleUpdate);
  const { roleUpdates } = roleUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("kpi" in fieldValues)
      temp.kpi = fieldValues.kpi ? "" : "This field is required.";

    if ("objective" in fieldValues)
      temp.objective = fieldValues.objective ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const kpi = values.kpi;
  const objective = values.objective;
  const balance_dimention_id = user_role_id;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        kpisUpdateAction(
          id,
          kpi,
          objective,
          balance_dimention_id,
          frequency,
          direction,
          value_format,
          counting
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(kpisList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openEditPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        KPI Setup
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setLastName={setLastName}
          setEmployeeId={setEmployeeId}
          setFrequency={setFrequency}
          setDirection={setDirection}
          setValueFormat={setValueFormat}
          setCounting={setCounting}
          setPhoto={setPhoto}
          setUserStatus={setUserStatus}
          item={item}
          user_status={user_status}
          setRoleId={setRoleId}
          dimentions={dimentions}
          setSignPhoto={setSignPhoto}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
