import axios from "axios";
import {
  KPI_Years_REQUEST,
  KPI_Years_SUCCESS,
  KPI_Years_FAIL,
  KPI_Years_CREATE_REQUEST,
  KPI_Years_CREATE_SUCCESS,
  KPI_Years_CREATE_FAIL,
  KPI_Years_UPDATE_REQUEST,
  KPI_Years_UPDATE_SUCCESS,
  KPI_Years_UPDATE_FAIL,
  KPI_Years_DELETE_REQUEST,
  KPI_Years_DELETE_SUCCESS,
  KPI_Years_DELETE_FAIL,
} from "../constants/kpiYearsConstant";

export const kpiYearsList = () => async (dispatch) => {
  try {
    dispatch({
      type: KPI_Years_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/kpiyears",
      config
    );

    dispatch({
      type: KPI_Years_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_Years_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const kpiYearsCreateAction =
  (kpi_year, from_date, to_date) => async (dispatch) => {
    try {
      dispatch({
        type: KPI_Years_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpiyears",
        {
          kpi_year,
          from_date,
          to_date,
        },
        config
      );

      dispatch({
        type: KPI_Years_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPI_Years_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const kpiYearsUpdateAction =
  (id, kpi_year, from_date, to_date) => async (dispatch) => {
    try {
      dispatch({
        type: KPI_Years_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/kpiyears/${id}`,
        {
          id,
          kpi_year,
          from_date,
          to_date,
        },
        config
      );

      dispatch({
        type: KPI_Years_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KPI_Years_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const kpiYearsDeleteAction = (id, setNotify) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_Years_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios
      .delete(`http://kpi.clonestudiobd.com/api/kpiyears/${id}`, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("abcd", error.response);
      });

    console.log("ooo", data);

    dispatch({
      type: KPI_Years_DELETE_SUCCESS,
    });
  } catch (response) {
    dispatch({
      type: KPI_Years_DELETE_FAIL,
      payload: response ? response.data : response,
    });
  }
};
