export const DESIGNATIONS_REQUEST = "DESIGNATIONS_REQUEST";
export const DESIGNATIONS_SUCCESS = "DESIGNATIONS_SUCCESS";
export const DESIGNATIONS_FAIL = "DESIGNATIONS_FAIL";

export const DESIGNATIONS_CREATE_REQUEST = "DESIGNATIONS_CREATE_REQUEST";
export const DESIGNATIONS_CREATE_SUCCESS = "DESIGNATIONS_CREATE_SUCCESS";
export const DESIGNATIONS_CREATE_FAIL = "DESIGNATIONS_CREATE_FAIL";
export const DESIGNATIONS_CREATE_RESET = "DESIGNATIONS_CREATE_RESET";

export const DESIGNATIONS_UPDATE_REQUEST = "DESIGNATIONS_UPDATE_REQUEST";
export const DESIGNATIONS_UPDATE_SUCCESS = "DESIGNATIONS_UPDATE_SUCCESS";
export const DESIGNATIONS_UPDATE_FAIL = "DESIGNATIONS_UPDATE_FAIL";
export const DESIGNATIONS_UPDATE_RESET = "DESIGNATIONS_UPDATE_RESET";

export const DESIGNATIONS_DELETE_REQUEST = "DESIGNATIONS_DELETE_REQUEST";
export const DESIGNATIONS_DELETE_SUCCESS = "DESIGNATIONS_DELETE_SUCCESS";
export const DESIGNATIONS_DELETE_FAIL = "DESIGNATIONS_DELETE_FAIL";

export const DESIGNATIONS_PERMISSION_REQUEST = "DESIGNATIONS_PERMISSION_REQUEST";
export const DESIGNATIONS_PERMISSION_SUCCESS = "DESIGNATIONS_PERMISSION_SUCCESS";
export const DESIGNATIONS_PERMISSION_FAIL = "DESIGNATIONS_PERMISSION_FAIL";
