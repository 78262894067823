import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  approvalPanelMembersList,
  approvalPanelMembersCreateAction,
} from "../../../redux/actions/approvalPanelMembersAction";
import { CreateValidation } from "./FormValidation";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, notify, setNotify, users, approvalPanels } =
    props;

  const created_by = "Shahibuzzaman";
  const modified_by = "Shahibuzzaman2";

  const dispatch = useDispatch();
  const designationCreate = useSelector((state) => state.designationCreate);
  const { designationCreates } = designationCreate;
  console.log("addda", designationCreates);

  const [products, setProducts] = React.useState([
    {
      id: 1,
      user_id: "",
      sequence_No: "",
    },
  ]);

  const [approval_panel_id, setApprovalPanelId] = React.useState("");
  const [postData, setPostData] = React.useState("");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("designation" in fieldValues)
      temp.designation = fieldValues.designation
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const designation = values.designation;
  const alias = values.alias;
  const print_serial = values.print_serial;

  console.log(
    "department data",
    designation,
    alias,
    print_serial,
    created_by,
    modified_by
  );

  const handleClose = () => {
    setOpenPopup(false);
    setProducts([
      {
        id: 1,
        user_id: "",
        sequence_No: "",
      },
    ]);
    setApprovalPanelId("");
  };

  const handleAddEvent = () => {
    var id = Math.floor(Math.random() * 100);
    var product = {
      id: id,
      user_id: "",
      sequence_No: "",
    };

    setProducts((currentArray) => [...currentArray, product]);
  };

  console.log("products", products, approval_panel_id);

  React.useEffect(() => {
    const data = products.map((item) => ({
      id: item.id,
      approval_panel_id: approval_panel_id,
      user_id: item.user_id,
      sequence_No: item.sequence_No,
    }));

    setPostData(data);
  }, [products, approval_panel_id]);

  console.log("postData", postData);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(approvalPanelMembersCreateAction(postData));
    setOpenPopup(false);
    setTimeout(() => {
      dispatch(approvalPanelMembersList());
    }, 2000);
    setProducts([
      {
        id: 1,
        user_id: "",
        sequence_No: "",
      },
    ]);
    setApprovalPanelId("");
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      // onClose={handleClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Approval Panel Members
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          users={users}
          approvalPanels={approvalPanels}
          postData={postData}
          setProducts={setProducts}
          products={products}
          setApprovalPanelId={setApprovalPanelId}
          approval_panel_id={approval_panel_id}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20, flex: 1 }}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={6} style={{ textAlign: "left" }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={handleAddEvent}
              style={{ marginRight: 10 }}
            >
              Add New Row
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={handleClose}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={submitHandler}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
