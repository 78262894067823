import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    value,
    handleInputChange,
    error,
    helperText,

    // department_nameCheck,
    // setDepartmentNameCheck,
  } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // error={department_nameCheck ? true : false}
              // helperText={
              //   department_nameCheck ? "This field is required" : null
              // }
              name="objective"
              variant="outlined"
              required
              fullWidth
              id="objective"
              label="Objective"
              autoFocus
              size="small"
              defaultValue={item ? item.objective: null}
              // onChange={(e) => {
              //   setDepartmentName(e.target.value);
              //   setDepartmentNameCheck(false);
              // }}
              error={error.objective}
              helperText={helperText.objective}
              onChange={handleInputChange}
            />
          </Grid>
          
   
          <Grid item xs={12}>
            <TextField
              name="description"
              variant="outlined"
              fullWidth
              id="description"
              label="Description"
              autoFocus
              size="small"
              defaultValue={item ? item.description : null}
              // onChange={(e) => setPhoneNo(e.target.value)}
              // value={value.description}
              error={error.description}
              helperText={helperText.description}
              onChange={handleInputChange}
            />
          </Grid>





          {/* <Grid item xs={12}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="print_serial"
              label="Print Serial"
              autoFocus
              size="small"
              defaultValue={item ? item.print_serial : null}
              // onChange={(e) => setEmailAddress(e.target.value)}
              value={value.print_serial}
              error={error.print_serial}
              helperText={helperText.print_serial}
              onChange={handleInputChange}
            />
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
