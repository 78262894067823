import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    organization_name: "",
    organization_alias: "",
    organization_type: "",
    address: "",
    phone_no: "",
    email_address: "",
    website: "",
    logo: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues({
      organization_name: "",
      organization_alias: "",
      organization_type: "",
      address: "",
      phone_no: "",
      email_address: "",
      website: "",
      logo: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    organization_name: item ? item.organization_name : "",
    organization_alias: item ? item.organization_alias : "",
    organization_type: item ? item.organization_type : "",
    address: item ? item.address : "",
    phone_no: item ? item.phone_no : "",
    email_address: item ? item.email_address : "",
    website: item ? item.website : "",
    logo: item ? item.logo : "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      organization_name: item.organization_name,
      organization_alias: item.organization_alias,
      organization_type: item.organization_type,
      address: item.address,
      phone_no: item.phone_no,
      email_address: item.email_address,
      website: item.website,

      logo: item.logo,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
