import React, { forwardRef } from "react";
import ListItem from "@material-ui/core/ListItem";
import { NavLink, NavLinkProps } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const ModuleItemComponets = ({ ...props }) => {
  const { className, onClick, menu_link, parent_menu, children } = props;

  const ListItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "#273746",
        color: "white",
        borderRadius: 2,
      },
      "&$selected:hover": {
        backgroundColor: "purple",
        color: "white",
      },
    },
    selected: {},
  })(MuiListItem);

  if (menu_link === "/") {
    return (
      <ListItem
        button
        name="event"
        className={className}
        children={children}
        onClick={onClick}
      />
    );
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink exact {...props} innerRef={ref} />
      ))}
      exact
      to={`/app${menu_link}`}
    />
  );
};

export default ModuleItemComponets;
