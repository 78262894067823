export const KPI_OBJECTIVES_REQUEST = "KPI_OBJECTIVES_REQUEST";
export const KPI_OBJECTIVES_SUCCESS = "KPI_OBJECTIVES_SUCCESS";
export const KPI_OBJECTIVES_FAIL = "KPI_OBJECTIVES_FAIL";

export const KPI_OBJECTIVES_CREATE_REQUEST = "KPI_OBJECTIVES_CREATE_REQUEST";
export const KPI_OBJECTIVES_CREATE_SUCCESS = "KPI_OBJECTIVES_CREATE_SUCCESS";
export const KPI_OBJECTIVES_CREATE_FAIL = "KPI_OBJECTIVES_CREATE_FAIL";
export const KPI_OBJECTIVES_CREATE_RESET = "KPI_OBJECTIVES_CREATE_RESET";

export const KPI_OBJECTIVES_UPDATE_REQUEST = "KPI_OBJECTIVES_UPDATE_REQUEST";
export const KPI_OBJECTIVES_UPDATE_SUCCESS = "KPI_OBJECTIVES_UPDATE_SUCCESS";
export const KPI_OBJECTIVES_UPDATE_FAIL = "KPI_OBJECTIVES_UPDATE_FAIL";
export const KPI_OBJECTIVES_UPDATE_RESET = "KPI_OBJECTIVES_UPDATE_RESET";

export const KPI_OBJECTIVES_DELETE_REQUEST = "KPI_OBJECTIVES_DELETE_REQUEST";
export const KPI_OBJECTIVES_DELETE_SUCCESS = "KPI_OBJECTIVES_DELETE_SUCCESS";
export const KPI_OBJECTIVES_DELETE_FAIL = "KPI_OBJECTIVES_DELETE_FAIL";

export const KPI_OBJECTIVES_PERMISSION_REQUEST = "KPI_OBJECTIVES_PERMISSION_REQUEST";
export const KPI_OBJECTIVES_PERMISSION_SUCCESS = "KPI_OBJECTIVES_PERMISSION_SUCCESS";
export const KPI_OBJECTIVES_PERMISSION_FAIL = "KPI_OBJECTIVES_PERMISSION_FAIL";
