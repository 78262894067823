import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Table from "./Table";
import KpiTable from "./KpiTable";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { kpisList } from "../../../redux/actions/kpisAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import { employeeKpisList } from "../../../redux/actions/employeeKpisActions";
import { dimentionsList } from "../../../redux/actions/dimentionsAction";
import { employeesList } from "../../../redux/actions/employeeAction";
import { kpimappingsList } from "../../../redux/actions/kpimappingsAction";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  selectEmpty: {
    marginTop: theme.spacing(12),
  },
}));

const Body = (props) => {
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const [filterType, setFilterType] = React.useState("employee");

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(61);

  const [employee_id, setEmployeeId] = useState("");
  const [kpi_id, setKpiId] = useState("");

  const employeeKpi = useSelector((state) => state.employeeKpi);

  const { employeeKpis } = employeeKpi;

  const employee = useSelector((state) => state.employee);

  const { employees } = employee;

  const kpimapping = useSelector((state) => state.kpimapping);

  const { kpimappings } = kpimapping;

  const kpi = useSelector((state) => state.kpi);

  const { kpis } = kpi;

  const dimention = useSelector((state) => state.dimention);

  const { dimentions } = dimention;

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission - xyz", employeeKpis);
  console.log(userData);
  console.log("emp-id", employee_id);

  useEffect(() => {
    dispatch(employeesList());
    dispatch(kpimappingsList());
    dispatch(kpisList());
    dispatch(employeeKpisList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  const tableData = employee_id
    ? employeeKpis
        .filter((item) => employee_id.includes(item.employee_id))
        .map((item) => item)
    : null;

  const KpiTableData = kpi_id
    ? employeeKpis
        .filter((item) => kpi_id.includes(item.kpi_id))
        .map((item) => item)
    : null;

  console.log("t data", KpiTableData);
  console.log("abcd", employeeKpis);

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  return (
    <div style={{ minHeight: "80vh" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>timeline</Icon>
        <h2 style={{ marginLeft: 5 }}> Employee KPIs</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>My KPI</p>
        <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
        <p>Employee KPIs</p>
      </div>

      <div style={{ marginTop: 20, flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
          style={{ backgroundColor: "#EAF3DD", borderRadius: 5 }}
        >
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Filter By</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={filterType}
                onChange={handleFilterTypeChange}
              >
                <FormControlLabel
                  value="employee"
                  control={<Radio color="primary" />}
                  label="Employee"
                />
                <FormControlLabel
                  value="kpi"
                  control={<Radio color="primary" />}
                  label="KPI"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                {filterType === "employee" ? "Select Employee" : "Select KPI"}
              </InputLabel>
              {filterType === "employee" ? (
                <Select
                  native
                  value={employee_id}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  label="Select Employee"
                  inputProps={{
                    name: "Select Employee",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  {employees
                    ? employees.map((item) => (
                        <option value={item.id}>
                          {item.First_Name} {item.last_name} ({item.org_ref_id})
                        </option>
                      ))
                    : null}
                </Select>
              ) : (
                // <Autocomplete
                //   id="country-select-demo"
                //   style={{ width: 300 }}
                //   options={employees}
                //   classes={{
                //     option: classes.option,
                //   }}
                //   autoHighlight
                //   getOptionLabel={(option) =>
                //     `${option.First_Name} ${option.last_name}`
                //   }
                //   renderOption={(option) => (
                //     <React.Fragment>
                //       {option.First_Name} {option.last_name}
                //     </React.Fragment>
                //   )}

                //   onChange={(e, v) => setEmployeeId(v.id)}
                //   renderInput={(params) => (
                //     <TextField
                //       {...params}
                //       label="Choose a country"
                //       variant="outlined"
                //       inputProps={{
                //         ...params.inputProps,
                //         autoComplete: "new-password", // disable autocomplete and autofill
                //       }}
                //     />
                //   )}
                // />
                <Select
                  native
                  value={kpi_id}
                  onChange={(e) => setKpiId(e.target.value)}
                  label="Select KPI"
                  inputProps={{
                    name: "Select KPI",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />
                  {kpis
                    ? kpis.map((item) => (
                        <option value={item.id}>{item.kpi}</option>
                      ))
                    : null}
                </Select>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {filterType === "employee" && tableData !== null ? (
          <div style={{ marginTop: 20 }}>
            <Table
              tableData={tableData}
              permissions={permissions}
              kpis={kpis}
              dimentions={dimentions}
              employee={employees}
              kpiMapping={kpimappings}
              employee_id={employee_id}
            />
          </div>
        ) : filterType === "kpi" && KpiTableData !== null ? (
          <div style={{ marginTop: 20 }}>
            <KpiTable
              tableData={KpiTableData}
              permissions={permissions}
              kpis={kpis}
              dimentions={dimentions}
              employee={employees}
              kpiMapping={kpimappings}
              kpi_id={kpi_id}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Body;
