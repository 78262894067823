import axios from "axios";
import {
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,
  DEPARTMENT_CREATE_REQUEST,
  DEPARTMENT_CREATE_SUCCESS,
  DEPARTMENT_CREATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_PERMISSION_REQUEST,
  DEPARTMENT_PERMISSION_SUCCESS,
  DEPARTMENT_PERMISSION_FAIL,
} from "../constants/departmentConstant";

export const departmentList = () => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/departments"
    );

    dispatch({
      type: DEPARTMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const departmentCreateAction =
  (
    department_name,
    department_alias,
    approval_pannel_id,
    print_serial,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DEPARTMENT_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/departments",
        {
          department_name,
          department_alias,
          approval_pannel_id,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "data4",
        department_name,
        department_alias,
        print_serial,
        created_by,
        modified_by
      );

      dispatch({
        type: DEPARTMENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEPARTMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const departmentUpdateAction =
  (
    id,
    department_name,
    department_alias,
    approval_pannel_id,
    print_serial,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DEPARTMENT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/departments/${id}`,
        {
          department_name,
          department_alias,
          approval_pannel_id,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        department_name,
        department_alias,
        print_serial,
        created_by,
        modified_by
      );

      dispatch({
        type: DEPARTMENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEPARTMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const departmentDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/departments/${id}`,
      config
    );

    dispatch({
      type: DEPARTMENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const departmentPermissionAction =
  (user_role_id, app_menu_id) => async (dispatch) => {
    try {
      dispatch({
        type: DEPARTMENT_PERMISSION_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      console.log("DataCheck", user_role_id, app_menu_id);

      const { data } = await axios.post(
        "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
        {
          user_role_id: user_role_id,
          app_menu_id: app_menu_id,
        },
        config
      );

      console.log("DataP", user_role_id, app_menu_id);

      dispatch({
        type: DEPARTMENT_PERMISSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEPARTMENT_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
