export const EMPLOYEE_KPIS_REQUEST = "EMPLOYEE_KPIS_REQUEST";
export const EMPLOYEE_KPIS_SUCCESS = "EMPLOYEE_KPIS_SUCCESS";
export const EMPLOYEE_KPIS_FAIL = "EMPLOYEE_KPIS_FAIL";

export const EMPLOYEE_KPIS_WITH_ID_REQUEST = "EMPLOYEE_KPIS_WITH_ID_REQUEST";
export const EMPLOYEE_KPIS_WITH_ID_SUCCESS = "EMPLOYEE_KPIS_WITH_ID_SUCCESS";
export const EMPLOYEE_KPIS_WITH_ID_FAIL = "EMPLOYEE_KPIS_WITH_ID_FAIL";

export const EMPLOYEE_WITH_KPI_REQUEST = "EMPLOYEE_WITH_KPI_REQUEST";
export const EMPLOYEE_WITH_KPI_SUCCESS = "EMPLOYEE_WITH_KPI_SUCCESS";
export const EMPLOYEE_WITH_KPI_FAIL = "EMPLOYEE_WITH_KPI_FAIL";

export const EMPLOYEE_KPIS_CREATE_REQUEST = "EMPLOYEE_KPIS_CREATE_REQUEST";
export const EMPLOYEE_KPIS_CREATE_SUCCESS = "EMPLOYEE_KPIS_CREATE_SUCCESS";
export const EMPLOYEE_KPIS_CREATE_FAIL = "EMPLOYEE_KPIS_CREATE_FAIL";
export const EMPLOYEE_KPIS_CREATE_RESET = "EMPLOYEE_KPIS_CREATE_RESET";

export const EMPLOYEE_KPIS_UPDATE_REQUEST = "EMPLOYEE_KPIS_UPDATE_REQUEST";
export const EMPLOYEE_KPIS_UPDATE_SUCCESS = "EMPLOYEE_KPIS_UPDATE_SUCCESS";
export const EMPLOYEE_KPIS_UPDATE_FAIL = "EMPLOYEE_KPIS_UPDATE_FAIL";
export const EMPLOYEE_KPIS_UPDATE_RESET = "EMPLOYEE_KPIS_UPDATE_RESET";

export const EMPLOYEE_KPIS_DELETE_REQUEST = "EMPLOYEE_KPIS_DELETE_REQUEST";
export const EMPLOYEE_KPIS_DELETE_SUCCESS = "EMPLOYEE_KPIS_DELETE_SUCCESS";
export const EMPLOYEE_KPIS_DELETE_FAIL = "EMPLOYEE_KPIS_DELETE_FAIL";
