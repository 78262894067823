import {
  APPROVAL_LIST_REQUEST,
  APPROVAL_LIST_SUCCESS,
  APPROVAL_LIST_FAIL,
  APPROVAL_FORWARD_REQUEST,
  APPROVAL_FORWARD_SUCCESS,
  APPROVAL_FORWARD_FAIL,
  APPROVAL_CREATE_REQUEST,
  APPROVAL_CREATE_SUCCESS,
  APPROVAL_CREATE_FAIL,
  APPROVAL_MODIFY_REQUEST,
  APPROVAL_MODIFY_SUCCESS,
  APPROVAL_MODIFY_FAIL,
  APPROVAL_ENTRY_LIST_REQUEST,
  APPROVAL_ENTRY_LIST_SUCCESS,
  APPROVAL_ENTRY_LIST_FAIL,
  APPROVAL_FORWARD_NEXT_REQUEST,
  APPROVAL_FORWARD_NEXT_SUCCESS,
  APPROVAL_FORWARD_NEXT_FAIL,
} from "../constants/approvalConstant";

export const approvalReducer = (state = { approvals: [] }, action) => {
  switch (action.type) {
    case APPROVAL_LIST_REQUEST:
      return { loading: true, approvals: [] };
    case APPROVAL_LIST_SUCCESS:
      return { loading: false, approvals: action.payload };
    case APPROVAL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalForwardNextReducer = (
  state = { approvalForwardNexts: [] },
  action
) => {
  switch (action.type) {
    case APPROVAL_FORWARD_NEXT_REQUEST:
      return { loading: true, approvalForwardNexts: [] };
    case APPROVAL_FORWARD_NEXT_SUCCESS:
      return { loading: false, approvalForwardNexts: action.payload };
    case APPROVAL_FORWARD_NEXT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalEntryReducer = (
  state = { approvalEntries: [] },
  action
) => {
  switch (action.type) {
    case APPROVAL_ENTRY_LIST_REQUEST:
      return { loading: true, approvalEntries: [] };
    case APPROVAL_ENTRY_LIST_SUCCESS:
      return { loading: false, approvalEntries: action.payload };
    case APPROVAL_ENTRY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalForwardReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_FORWARD_REQUEST:
      return { loading: true };
    case APPROVAL_FORWARD_SUCCESS:
      return { loading: false, approvalForwardCreates: action.payload };
    case APPROVAL_FORWARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_CREATE_REQUEST:
      return { loading: true };
    case APPROVAL_CREATE_SUCCESS:
      return { loading: false, approvalCreates: action.payload };
    case APPROVAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_MODIFY_REQUEST:
      return { loading: true };
    case APPROVAL_MODIFY_SUCCESS:
      return { loading: false, approvalUpdates: action.payload };
    case APPROVAL_MODIFY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
