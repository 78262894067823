export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAIL = "USER_ROLE_FAIL";

export const USER_ROLE_CREATE_REQUEST = "USER_ROLE_CREATE_REQUEST";
export const USER_ROLE_CREATE_SUCCESS = "USER_ROLE_CREATE_SUCCESS";
export const USER_ROLE_CREATE_FAIL = "USER_ROLE_CREATE_FAIL";
export const USER_ROLE_CREATE_RESET = "USER_ROLE_CREATE_RESET";

export const USER_ROLE_UPDATE_REQUEST = "USER_ROLE_UPDATE_REQUEST";
export const USER_ROLE_UPDATE_SUCCESS = "USER_ROLE_UPDATE_SUCCESS";
export const USER_ROLE_UPDATE_FAIL = "USER_ROLE_UPDATE_FAIL";
export const USER_ROLE_UPDATE_RESET = "USER_ROLE_UPDATE_RESET";

export const USER_ROLE_DELETE_REQUEST = "USER_ROLE_DELETE_REQUEST";
export const USER_ROLE_DELETE_SUCCESS = "USER_ROLE_DELETE_SUCCESS";
export const USER_ROLE_DELETE_FAIL = "USER_ROLE_DELETE_FAIL";

export const ROLE_FUNCTION_REQUEST = "ROLE_FUNCTION_REQUEST";
export const ROLE_FUNCTION_SUCCESS = "ROLE_FUNCTION_SUCCESS";
export const ROLE_FUNCTION_FAIL = "ROLE_FUNCTION_FAIL";

export const ROLE_FUNCTION_CREATE_REQUEST = "ROLE_FUNCTION_CREATE_REQUEST";
export const ROLE_FUNCTION_CREATE_SUCCESS = "ROLE_FUNCTION_CREATE_SUCCESS";
export const ROLE_FUNCTION_CREATE_FAIL = "ROLE_FUNCTION_CREATE_FAIL";
export const ROLE_FUNCTION_CREATE_RESET = "ROLE_FUNCTION_CREATE_RESET";

export const ROLE_FUNCTION_UPDATE_REQUEST = "ROLE_FUNCTION_UPDATE_REQUEST";
export const ROLE_FUNCTION_UPDATE_SUCCESS = "ROLE_FUNCTION_UPDATE_SUCCESS";
export const ROLE_FUNCTION_UPDATE_FAIL = "ROLE_FUNCTION_UPDATE_FAIL";
export const ROLE_FUNCTION_UPDATE_RESET = "ROLE_FUNCTION_UPDATE_RESET";

export const ROLE_FUNCTION_DELETE_REQUEST = "ROLE_FUNCTION_DELETE_REQUEST";
export const ROLE_FUNCTION_DELETE_SUCCESS = "ROLE_FUNCTION_DELETE_SUCCESS";
export const ROLE_FUNCTION_DELETE_FAIL = "ROLE_FUNCTION_DELETE_FAIL";
