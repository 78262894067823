import {
  APPROVAL_PANEL_REQUEST,
  APPROVAL_PANEL_SUCCESS,
  APPROVAL_PANEL_FAIL,
  APPROVAL_PANEL_CREATE_REQUEST,
  APPROVAL_PANEL_CREATE_SUCCESS,
  APPROVAL_PANEL_CREATE_FAIL,
  APPROVAL_PANEL_CREATE_RESET,
  APPROVAL_PANEL_UPDATE_REQUEST,
  APPROVAL_PANEL_UPDATE_SUCCESS,
  APPROVAL_PANEL_UPDATE_FAIL,
  APPROVAL_PANEL_DELETE_REQUEST,
  APPROVAL_PANEL_DELETE_SUCCESS,
  APPROVAL_PANEL_DELETE_FAIL,
} from "../constants/approvalPanelConstant";

export const approvalPanelReducer = (
  state = { approvalPanels: [] },
  action
) => {
  switch (action.type) {
    case APPROVAL_PANEL_REQUEST:
      return { loading: true, approvalPanels: [] };
    case APPROVAL_PANEL_SUCCESS:
      return { loading: false, approvalPanels: action.payload };
    case APPROVAL_PANEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalPanelCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_CREATE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_CREATE_SUCCESS:
      return { loading: false, approvalPanelsCreate: action.payload };
    case APPROVAL_PANEL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case APPROVAL_PANEL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const approvalPanelUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_UPDATE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_UPDATE_SUCCESS:
      return { loading: false, approvalPanelUpdate: action.payload };
    case APPROVAL_PANEL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalPanelDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVAL_PANEL_DELETE_REQUEST:
      return { loading: true };
    case APPROVAL_PANEL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case APPROVAL_PANEL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
