import {
  SECTION_REQUEST,
  SECTION_SUCCESS,
  SECTION_FAIL,
  SECTION_CREATE_REQUEST,
  SECTION_CREATE_SUCCESS,
  SECTION_CREATE_FAIL,
  SECTION_CREATE_RESET,
  SECTION_UPDATE_REQUEST,
  SECTION_UPDATE_SUCCESS,
  SECTION_UPDATE_FAIL,
  SECTION_UPDATE_RESET,
  SECTION_DELETE_REQUEST,
  SECTION_DELETE_SUCCESS,
  SECTION_DELETE_FAIL,
} from "../constants/sectionConstants";

export const sectionReducer = (state = { sections: [] }, action) => {
  switch (action.type) {
    case SECTION_REQUEST:
      return { loading: true, sections: [] };
    case SECTION_SUCCESS:
      return { loading: false, sections: action.payload };
    case SECTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SECTION_CREATE_REQUEST:
      return { loading: true };
    case SECTION_CREATE_SUCCESS:
      return { loading: false, sectionCreates: action.payload };
    case SECTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SECTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sectionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SECTION_UPDATE_REQUEST:
      return { loading: true };
    case SECTION_UPDATE_SUCCESS:
      return { loading: false, sectionUpdates: action.payload };
    case SECTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SECTION_UPDATE_RESET:
      return { sectionCreates: {} };
    default:
      return state;
  }
};

export const sectionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SECTION_DELETE_REQUEST:
      return { loading: true };
    case SECTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SECTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
