import axios from "axios";
import {
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAIL,
  USER_ROLE_CREATE_REQUEST,
  USER_ROLE_CREATE_SUCCESS,
  USER_ROLE_CREATE_FAIL,
  USER_ROLE_UPDATE_REQUEST,
  USER_ROLE_UPDATE_SUCCESS,
  USER_ROLE_UPDATE_FAIL,
  USER_ROLE_DELETE_REQUEST,
  USER_ROLE_DELETE_SUCCESS,
  USER_ROLE_DELETE_FAIL,
  ROLE_FUNCTION_REQUEST,
  ROLE_FUNCTION_SUCCESS,
  ROLE_FUNCTION_FAIL,
  ROLE_FUNCTION_CREATE_REQUEST,
  ROLE_FUNCTION_CREATE_SUCCESS,
  ROLE_FUNCTION_CREATE_FAIL,
  ROLE_FUNCTION_CREATE_RESET,
  ROLE_FUNCTION_UPDATE_REQUEST,
  ROLE_FUNCTION_UPDATE_SUCCESS,
  ROLE_FUNCTION_UPDATE_FAIL,
  ROLE_FUNCTION_UPDATE_RESET,
  ROLE_FUNCTION_DELETE_REQUEST,
  ROLE_FUNCTION_DELETE_SUCCESS,
  ROLE_FUNCTION_DELETE_FAIL,
} from "../constants/userRoleConstants";

export const userRoleList = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/userroles",
      config
    );

    dispatch({
      type: USER_ROLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const userRoleCreateAction =
  (role_name, role_description, role_status) => async (dispatch) => {
    try {
      dispatch({
        type: USER_ROLE_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/userroles",
        {
          role_name,
          role_description,
          role_status,
        },
        config
      );

      console.log("data4", role_name, role_description, role_status);

      dispatch({
        type: USER_ROLE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_ROLE_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const userRoleUpdateAction =
  (id, role_name, role_description, role_status, created_by, modified_by) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_ROLE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/userroles/${id}`,
        { role_name, role_description, role_status, created_by, modified_by },
        config
      );

      console.log(
        "update data",
        role_name,
        role_description,
        role_status,
        created_by,
        modified_by
      );

      dispatch({
        type: USER_ROLE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_ROLE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const userRoleDeleteAction = (id, setNotify) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ROLE_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios
      .delete(`http://kpi.clonestudiobd.com/api/userroles/${id}`, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log("abcd", error.response);
      });

    console.log("ooo", data);

    dispatch({
      type: USER_ROLE_DELETE_SUCCESS,
    });
  } catch (response) {
    dispatch({
      type: USER_ROLE_DELETE_FAIL,
      payload: response ? response.data : response,
    });
  }
};

// role function

export const roleFunctionList = () => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_FUNCTION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/userrolefunctions",
      config
    );

    dispatch({
      type: ROLE_FUNCTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_FUNCTION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const roleFunctionCreateAction =
  (roleFunctionPush) => async (dispatch) => {
    try {
      dispatch({
        type: ROLE_FUNCTION_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("data4", roleFunctionPush);

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/userrolefunctions/bulk",
        roleFunctionPush,
        config
      );

      console.log("data45", data);

      dispatch({
        type: ROLE_FUNCTION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_FUNCTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const roleFunctionUpdateAction =
  (id, user_role_id, app_menu_id, crud_value) => async (dispatch) => {
    try {
      dispatch({
        type: ROLE_FUNCTION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/userrolefunctions/${id}`,
        { user_role_id, app_menu_id, crud_value },
        config
      );

      console.log("update data", user_role_id, app_menu_id, crud_value);

      dispatch({
        type: ROLE_FUNCTION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_FUNCTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const roleFunctionDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_FUNCTION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id333", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/userrolefunctions/${id}`,
      config
    );

    dispatch({
      type: ROLE_FUNCTION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ROLE_FUNCTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const roleFunctionBulkDeleteAction = (selected) => async (dispatch) => {
  try {
    dispatch({
      type: ROLE_FUNCTION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id333", selected);

    await axios
      .delete("http://kpi.clonestudiobd.com/api/userrolefunctions/bulk", {
        data: { data: selected },
        config,
      })
      .then((response) => {
        console.log(response);
      });

    dispatch({
      type: ROLE_FUNCTION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ROLE_FUNCTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
