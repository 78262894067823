import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setLastName,
    setRoleId,
    setFrequency,
    setDirection,
    setValueFormat,
    setCounting,
    setEmployeeId,
    setPhoto,
    user_status,
    setUserStatus,
    item,
    dimentions,
    setSignPhoto,
    value,
    handleInputChange,
    error,
    helperText,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  var sectionName = item
    ? dimentions
        .filter((o1) => {
          return o1.id === item.balance_dimention_id;
        })
        .map((item) => item.dimention)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Dimention *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setRoleId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? sectionName : "None"}</option>
                {dimentions
                  ? dimentions.map((item) => (
                      <option value={item.id}>{item.dimention}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="kpi"
              variant="outlined"
              required
              fullWidth
              id="kpi"
              label="KPI"
              autoFocus
              size="small"
              error={error.kpi}
              helperText={helperText.kpi}
              defaultValue={item ? item.kpi : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="objective"
              required
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              id="objective"
              label="Objective"
              autoFocus
              size="small"
              error={error.objective}
              helperText={helperText.objective}
              defaultValue={item ? item.objective : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Frequency *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setFrequency(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? item.frequency : "None"}</option>
                <option value="Monthly">Monthly</option>
                <option value="Quaterly">Quarterly</option>
                <option value="Half-yearly">Half Yearly</option>
                <option value="Yearly">Yearly</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Direction *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setDirection(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? item.direction : "None"}</option>
                <option value="Upper">Upper</option>
                <option value="Lower">Lower</option>
                <option value="None">None</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Value Format *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setValueFormat(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? item.value_format : "None"}</option>
                <option value="#">#</option>
                <option value="%">%</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Counting *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setCounting(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? item.counting : "None"}</option>
                <option value="Sum">Sum</option>
                <option value="Average">Average</option>
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
