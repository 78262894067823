import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  employeesList,
  organizationList,
  subSectionList,
  designationList,
  categoryList,
  departmentList,
  // departmentPermissionAction,
} from "../../../redux/actions/employeeAction";
import { approvalPanelList } from "../../../redux/actions/approvalPanelAction";


import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  //   const [app_menu_id, setAppMenuId] = useState(32);

  const employee = useSelector((state) => state.employee);
  const { employees } = employee;

  const organization = useSelector((state) => state.organization);
  const { organizations } = organization;

  const subSection = useSelector((state) => state.subSection);
  const { subSections } = subSection;

  const designation = useSelector((state) => state.designation);
  const { designations } = designation;

  const category = useSelector((state) => state.category);
  const { categories } = category;

  const department = useSelector((state) => state.department);
  const { departments } = department;

  const approvalPanel = useSelector((state) => state.approvalPanel);
  const { approvalPanels } = approvalPanel;

  //   const permission = useSelector((state) => state.permission);
  //   const departmentPermissions = useSelector(
  //     (state) => state.departmentPermission
  //   );
  //   const { departmentPermission } = departmentPermissions;

  //   console.log("permission - xyz", departmentPermissions);
  console.log(userData);

  useEffect(() => {
    dispatch(employeesList());
    dispatch(organizationList());
    dispatch(subSectionList());
    dispatch(designationList());
    dispatch(categoryList());
    dispatch(departmentList());
    dispatch(approvalPanelList());

    // dispatch(departmentPermissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>person</Icon>
        <h2 style={{ marginLeft: 5 }}>Employees</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Employee Profile</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p>Employees</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          employees={employees}
          organizations={organizations}
          subSections={subSections}
          designations={designations}
          categories={categories}
          departments={departments}
          approvalPanels={approvalPanels}
        />
        {/* <Table departments={departments} permissions={permissions} /> */}
      </div>
    </div>
  );
};

export default Body;
