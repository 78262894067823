import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import { userUpdateAction, userList } from "../../../redux/actions/userActions";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openEditPopup, setOpenEditPopup, item, roles, setNotify, employees } =
    props;
  const [last_name, setLastName] = React.useState("");
  const [user_role_id, setRoleId] = React.useState("");
  const [employee_id, setEmployeeId] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [user_status, setUserStatus] = React.useState("");
  const [sign_photo, setSignPhoto] = React.useState("");
  const id = item.id;

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setLastName(item.last_name);
    setRoleId(item.user_role_id);
    setEmployeeId(item.employee_id);
    setPhoto(item.photo);
    setUserStatus(item.user_status);
    setSignPhoto(item.sign_photo);
  }, [item]);

  const dispatch = useDispatch();
  const roleUpdate = useSelector((state) => state.roleUpdate);
  const { roleUpdates } = roleUpdate;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "This field is required.";

    if ("phone_no" in fieldValues)
      temp.phone_no =
        fieldValues.phone_no.length >= 11 ? "" : "This field is required.";

    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "Email is not valid.";

    if ("user_id" in fieldValues)
      temp.user_id = fieldValues.user_id ? "" : "This field is required.";

    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  const first_name = values.first_name;
  const phone_no = values.phone_no;
  const email = values.email;
  const user_id = values.user_id;
  const password = values.password;

  console.log("emp id", employee_id);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        userUpdateAction(
          id,
          first_name,
          last_name,
          phone_no,
          email,
          user_id,
          password,
          employee_id,
          photo,
          sign_photo,
          user_status,
          device_code,
          device_validation,
          user_role_id
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(userList());
      }, 2000);
      setNotify({
        isOpen: true,
        message: "Submitted Successfully!",
        type: "success",
      });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      onClose={handleClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        User
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          setLastName={setLastName}
          setEmployeeId={setEmployeeId}
          employee_id={employee_id}
          setPhoto={setPhoto}
          setUserStatus={setUserStatus}
          item={item}
          setRoleId={setRoleId}
          roles={roles}
          setSignPhoto={setSignPhoto}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
          employees={employees}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
