import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import clsx from "clsx";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import SimpleBarReact from "simplebar-react";

import "simplebar/src/simplebar.css";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "../sidebar/components/sideBarLink/SideBarLink";
import ModuleItems from "../sidebar/components/sideBarLink/ModuleItems";
import Dot from "../sidebar/components/Dot";
import { useDispatch, useSelector } from "react-redux";
import { toggleLeftSidebar } from "../redux/actions/globalStateActions";
import { moduleList } from "../redux/actions/moduleActions";
import Module from "./components/sideBarLink/Modules";

function Sidebar(props) {
  const { userData } = props;
  var classes = useStyles();
  var theme = useTheme();
  const [data, setData] = useState("");
  const [value, setValue] = useState({
    subModule: [],
  });

  console.log("ffffff", userData);

  const dispatch = useDispatch();

  const leftSidebar = useSelector((state) => state.leftSidebar);
  const { isSidebarOpened } = leftSidebar;
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton
          onClick={() => {
            dispatch(toggleLeftSidebar);
          }}
        >
          <ArrowBackIcon
            classes={{
              root: clsx(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <Module isSidebarOpened={isSidebarOpened} userData={userData} />
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
