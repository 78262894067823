export const DEPARTMENT_REQUEST = "DEPARTMENT_REQUEST";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL";

export const DEPARTMENT_CREATE_REQUEST = "DEPARTMENT_CREATE_REQUEST";
export const DEPARTMENT_CREATE_SUCCESS = "DEPARTMENT_CREATE_SUCCESS";
export const DEPARTMENT_CREATE_FAIL = "DEPARTMENT_CREATE_FAIL";
export const DEPARTMENT_CREATE_RESET = "DEPARTMENT_CREATE_RESET";

export const DEPARTMENT_UPDATE_REQUEST = "DEPARTMENT_UPDATE_REQUEST";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAIL = "DEPARTMENT_UPDATE_FAIL";
export const DEPARTMENT_UPDATE_RESET = "DEPARTMENT_UPDATE_RESET";

export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAIL = "DEPARTMENT_DELETE_FAIL";

export const DEPARTMENT_PERMISSION_REQUEST = "DEPARTMENT_PERMISSION_REQUEST";
export const DEPARTMENT_PERMISSION_SUCCESS = "DEPARTMENT_PERMISSION_SUCCESS";
export const DEPARTMENT_PERMISSION_FAIL = "DEPARTMENT_PERMISSION_FAIL";
