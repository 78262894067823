import {
  KPI_MAPPINGS_REQUEST,
  KPI_MAPPINGS_SUCCESS,
  KPI_MAPPINGS_FAIL,
  KPI_MAPPINGS_CREATE_REQUEST,
  KPI_MAPPINGS_CREATE_SUCCESS,
  KPI_MAPPINGS_CREATE_FAIL,
  KPI_MAPPINGS_CREATE_RESET,
  KPI_MAPPINGS_UPDATE_REQUEST,
  KPI_MAPPINGS_UPDATE_SUCCESS,
  KPI_MAPPINGS_UPDATE_FAIL,
  KPI_MAPPINGS_UPDATE_RESET,
  KPI_MAPPINGS_DELETE_REQUEST,
  KPI_MAPPINGS_DELETE_SUCCESS,
  KPI_MAPPINGS_DELETE_FAIL,
  KPI_MAPPINGS_PERMISSION_REQUEST,
  KPI_MAPPINGS_PERMISSION_SUCCESS,
  KPI_MAPPINGS_PERMISSION_FAIL,
} from "../constants/kpimappingsConstant";

export const kpimappingReducer = (state = { kpimappings: [] }, action) => {
  switch (action.type) {
    case KPI_MAPPINGS_REQUEST:
      return { loading: true, kpimappings: [] };
    case KPI_MAPPINGS_SUCCESS:
      return { loading: false, kpimappings: action.payload };
    case KPI_MAPPINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const kpimappingCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_MAPPINGS_CREATE_REQUEST:
      return { loading: true };
    case KPI_MAPPINGS_CREATE_SUCCESS:
      return { loading: false, kpimappingCreates: action.payload };
    case KPI_MAPPINGS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_MAPPINGS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const kpimappingUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_MAPPINGS_UPDATE_REQUEST:
      return { loading: true };
    case KPI_MAPPINGS_UPDATE_SUCCESS:
      return { loading: false, kpimappingUpdates: action.payload };
    case KPI_MAPPINGS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case KPI_MAPPINGS_UPDATE_RESET:
      return { kpimappingCreates: {} };
    default:
      return state;
  }
};

export const kpimappingDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KPI_MAPPINGS_DELETE_REQUEST:
      return { loading: true };
    case KPI_MAPPINGS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case KPI_MAPPINGS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//   export const departmentPermissionReducer = (
//     state = { departmentPermission: [] },
//     action
//   ) => {
//     switch (action.type) {
//       case DEPARTMENT_PERMISSION_REQUEST:
//         return { loading: true, departmentPermission: [] };
//       case DEPARTMENT_PERMISSION_SUCCESS:
//         return { loading: false, departmentPermission: action.payload };
//       case DEPARTMENT_PERMISSION_FAIL:
//         return { loading: false, error: action.payload };
//       default:
//         return state;
//     }
//   };
