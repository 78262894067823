import {
  EMPLOYEE_KPIS_REQUEST,
  EMPLOYEE_KPIS_SUCCESS,
  EMPLOYEE_KPIS_FAIL,
  EMPLOYEE_KPIS_WITH_ID_REQUEST,
  EMPLOYEE_KPIS_WITH_ID_SUCCESS,
  EMPLOYEE_KPIS_WITH_ID_FAIL,
  EMPLOYEE_KPIS_CREATE_REQUEST,
  EMPLOYEE_KPIS_CREATE_SUCCESS,
  EMPLOYEE_KPIS_CREATE_FAIL,
  EMPLOYEE_KPIS_CREATE_RESET,
  EMPLOYEE_KPIS_UPDATE_REQUEST,
  EMPLOYEE_KPIS_UPDATE_SUCCESS,
  EMPLOYEE_KPIS_UPDATE_FAIL,
  EMPLOYEE_KPIS_UPDATE_RESET,
  EMPLOYEE_KPIS_DELETE_REQUEST,
  EMPLOYEE_KPIS_DELETE_SUCCESS,
  EMPLOYEE_KPIS_DELETE_FAIL,
  EMPLOYEE_WITH_KPI_REQUEST,
  EMPLOYEE_WITH_KPI_SUCCESS,
  EMPLOYEE_WITH_KPI_FAIL,
} from "../constants/employeeKpisConstants";

export const employeeKpisReducer = (state = { employeeKpis: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_KPIS_REQUEST:
      return { loading: true, employeeKpis: [] };
    case EMPLOYEE_KPIS_SUCCESS:
      return { loading: false, employeeKpis: action.payload };
    case EMPLOYEE_KPIS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeeKpisWithIdReducer = (
  state = { employeeKpiWithIds: [] },
  action
) => {
  switch (action.type) {
    case EMPLOYEE_KPIS_WITH_ID_REQUEST:
      return { loading: true, employeeKpiWithIds: [] };
    case EMPLOYEE_KPIS_WITH_ID_SUCCESS:
      return { loading: false, employeeKpiWithIds: action.payload };
    case EMPLOYEE_KPIS_WITH_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeeWithKpiReducer = (
  state = { employeeWithKpis: [] },
  action
) => {
  switch (action.type) {
    case EMPLOYEE_WITH_KPI_REQUEST:
      return { loading: true, employeeWithKpis: [] };
    case EMPLOYEE_WITH_KPI_SUCCESS:
      return { loading: false, employeeWithKpis: action.payload };
    case EMPLOYEE_WITH_KPI_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const employeeKpisCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_KPIS_CREATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_KPIS_CREATE_SUCCESS:
      return { loading: false, employeeKpisCreates: action.payload };
    case EMPLOYEE_KPIS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_KPIS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const employeeKpisUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_KPIS_UPDATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_KPIS_UPDATE_SUCCESS:
      return { loading: false, employeeKpisUpdates: action.payload };
    case EMPLOYEE_KPIS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_KPIS_UPDATE_RESET:
      return { employeeKpisCreates: {} };
    default:
      return state;
  }
};

export const employeeKpisDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_KPIS_DELETE_REQUEST:
      return { loading: true };
    case EMPLOYEE_KPIS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EMPLOYEE_KPIS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
