import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 350,
  },
  formControl2: {
    minWidth: 50,
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    items,
    value,
    handleInputChange,
    error,
    helperText,
    users,
    approvalPanels,
    postData,
    setProducts,
    products,
    setApprovalPanelId,
    approval_panel_id,
    panelName,
  } = props;

  console.log("edit data", items);

  const handleAddProductTable = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value !== "" ? evt.target.value : "",
    };

    var id = parseInt(item.id, 10);

    var value = JSON.parse(item.value.toLowerCase());
    console.log("iddd5", item);

    var newProducts = products.map(function (product) {
      for (var key in product) {
        console.log("prr", products);
        if (key === item.name && product.id === id) {
          product[key] = value;
        }
      }

      return product;
    });
    setProducts(newProducts);
    // var marge = products.concat(userRoleFunctions);
    // setToggleButton(true);
    // setRoleFunctionPush({
    //   data: marge,
    // });
    console.log("xyx", newProducts);
  };

  const handleRowDel = (product) => {
    var index = products.indexOf(product);
    products.splice(index, 1);
    console.log("pro", products);
    setProducts([...products]);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <FormLabel component="legend" style={{ marginBottom: 10 }}>
                Approval Panel
              </FormLabel>
              <Select
                native
                value={approval_panel_id}
                onChange={(e) => setApprovalPanelId(e.target.value)}
              >
                <option value="">{items ? panelName[0] : "None"}</option>
                {approvalPanels
                  ? approvalPanels.map((item) => (
                      <option value={item.id}>{`${item.panel_name}`}</option>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {products.length
          ? products.map((item) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth="true" variant="outlined">
                      <FormLabel
                        component="legend"
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Approval Panel Member
                      </FormLabel>
                      <Select
                        native
                        style={{ fontSize: 16 }}
                        name={"user_id"}
                        id={item.id}
                        value={item.user_id}
                        onChange={(evt) => handleAddProductTable(evt)}
                      >
                        <option value="None">
                          {item.user_id !== ""
                            ? `${item.first_name} ${item.last_name}`
                            : "None"}
                        </option>
                        {users
                          ? users.map((item) => (
                              <option
                                value={item.id}
                              >{`${item.first_name} ${item.last_name}`}</option>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth="true" variant="outlined">
                      <FormLabel
                        component="legend"
                        style={{ marginBottom: 10, marginTop: 26 }}
                      ></FormLabel>
                      <TextField
                        name="sequence_No"
                        variant="outlined"
                        fullWidth
                        id={item.id}
                        label="Sequence No."
                        autoFocus
                        defaultValue={items ? item.sequence_No : null}
                        //  value={items ? item.sequence_No : ""}
                        onChange={(evt) => handleAddProductTable(evt)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ marginTop: 30 }}>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(item) => {
                            handleRowDel(item);
                          }}
                        >
                          <DeleteIcon
                            fontSize="small"
                            style={{ fill: "red" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </form>
    </div>
  );
};

export default InputForm;
