import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    organization_name,
    setOrganizationName,
    tag_line,
    setTagLine,
    street,
    setStreet,
    city,
    setCity,
    zip_code,
    setZipCode,
    phone_no,
    setPhoneNo,
    email_address,
    setEmailAddress,
    website,
    setWebsite,
    vat_registration_no,
    setVatRegistrationNo,
    base_currency,
    setBaseCurrency,
    country,
    setCountry,
    setLogo,
    item,
    // check,
    // setCheck,
    value,
    handleInputChange,
    error,
    helperText,
  } = props;

  console.log("edit data", item);

  const handleLogoUpload = (event) => {
    const files = event.target.files;
    setLogo(files[0]);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // error={check ? true : false}
              // helperText={check ? "This field is required" : null}
              name="organization_name"
              required
              variant="outlined"
              fullWidth
              id="organization_name"
              label="Organization Name"
              autoFocus
              size="small"
              // onChange={(e) => {
              //   setOrganizationName(e.target.value);
              //   setCheck(false);
              // }}
              error={error.organization_name}
              defaultValue={item ? item.organization_name : null}
              helperText={helperText.organization_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="organization_alias"
              variant="outlined"
              fullWidth
              id="organization_alias"
              label="Organization Alias"
              autoFocus
              size="small"
              defaultValue={item ? item.organization_alias : null}
              // onChange={(e) => setTagLine(e.target.value)}
              value={value.organization_alias}
              error={error.organization_alias}
              helperText={helperText.organization_alias}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="organization_type"
              variant="outlined"
              fullWidth
              id="organization_type"
              label="Organization Type"
              autoFocus
              size="small"
              defaultValue={item ? item.organization_type : null}
              // onChange={(e) => setStreet(e.target.value)}
              value={value.organization_type}
              error={error.organization_type}
              helperText={helperText.organization_type}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              id="address"
              label="Address"
              autoFocus
              size="small"
              defaultValue={item ? item.address : null}
              // onChange={(e) => setCity(e.target.value)}
              value={value.address}
              error={error.address}
              helperText={helperText.address}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="zip_code"
              variant="outlined"
              fullWidth
              id="zip_code"
              label="Zip Code"
              autoFocus
              size="small"
              defaultValue={item ? item.zip_code : null}
              // onChange={(e) => setZipCode(e.target.value)}
              value={value.zip_code}
              error={error.zip_code}
              helperText={helperText.zip_code}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone_no"
              variant="outlined"
              fullWidth
              id="phone_no"
              label="Phone No"
              autoFocus
              size="small"
              defaultValue={item ? item.phone_no : null}
              // onChange={(e) => setPhoneNo(e.target.value)}
              value={value.phone_no}
              error={error.phone_no}
              helperText={helperText.phone_no}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email_address"
              variant="outlined"
              fullWidth
              id="email_address"
              label="Email Address"
              autoFocus
              size="small"
              defaultValue={item ? item.email_address : null}
              // onChange={(e) => setEmailAddress(e.target.value)}
              value={value.email_address}
              error={error.email_address}
              helperText={helperText.email_address}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="website"
              variant="outlined"
              fullWidth
              id="website"
              label="Website"
              autoFocus
              size="small"
              defaultValue={item ? item.website : null}
              // onChange={(e) => setWebsite(e.target.value)}
              value={value.website}
              error={error.website}
              helperText={helperText.website}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="vat_registration_no"
              variant="outlined"
              fullWidth
              id="vat_registration_no"
              label="Vat Registration No"
              autoFocus
              size="small"
              defaultValue={item ? item.vat_registration_no : null}
              // onChange={(e) => setVatRegistrationNo(e.target.value)}
              value={value.vat_registration_no}
              error={error.vat_registration_no}
              helperText={helperText.vat_registration_no}
              onChange={handleInputChange}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="base_currency"
              variant="outlined"
              fullWidth
              id="base_currency"
              label="Base Currency"
              autoFocus
              size="small"
              defaultValue={item ? item.base_currency : null}
              // onChange={(e) => setBaseCurrency(e.target.value)}
              value={value.base_currency}
              error={error.base_currency}
              helperText={helperText.base_currency}
              onChange={handleInputChange}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="country"
              variant="outlined"
              fullWidth
              id="country"
              label="Country"
              autoFocus
              size="small"
              defaultValue={item ? item.country : null}
              // onChange={(e) => setCountry(e.target.value)}
              value={value.country}
              error={error.country}
              helperText={helperText.country}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Logo</FormLabel>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleLogoUpload}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
