import {
  SUB_SECTION_REQUEST,
  SUB_SECTION_SUCCESS,
  SUB_SECTION_FAIL,
  SUB_SECTION_CREATE_REQUEST,
  SUB_SECTION_CREATE_SUCCESS,
  SUB_SECTION_CREATE_FAIL,
  SUB_SECTION_CREATE_RESET,
  SUB_SECTION_UPDATE_REQUEST,
  SUB_SECTION_UPDATE_SUCCESS,
  SUB_SECTION_UPDATE_FAIL,
  SUB_SECTION_UPDATE_RESET,
  SUB_SECTION_DELETE_REQUEST,
  SUB_SECTION_DELETE_SUCCESS,
  SUB_SECTION_DELETE_FAIL,
} from "../constants/subSectionConstants";

export const subSectionReducer = (state = { subSections: [] }, action) => {
  switch (action.type) {
    case SUB_SECTION_REQUEST:
      return { loading: true, subSections: [] };
    case SUB_SECTION_SUCCESS:
      return { loading: false, subSections: action.payload };
    case SUB_SECTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subSectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_SECTION_CREATE_REQUEST:
      return { loading: true };
    case SUB_SECTION_CREATE_SUCCESS:
      return { loading: false, subSectionCreates: action.payload };
    case SUB_SECTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SUB_SECTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const subSectionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_SECTION_UPDATE_REQUEST:
      return { loading: true };
    case SUB_SECTION_UPDATE_SUCCESS:
      return { loading: false, subSectionUpdates: action.payload };
    case SUB_SECTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SUB_SECTION_UPDATE_RESET:
      return { subSectionCreates: {} };
    default:
      return state;
  }
};

export const subSectionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_SECTION_DELETE_REQUEST:
      return { loading: true };
    case SUB_SECTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SUB_SECTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
