import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { useDispatch, useSelector } from "react-redux";
import { kpisCreateAction, kpisList } from "../../../redux/actions/kpisAction";
import { kpiScoresUpdateAction } from "../../../redux/actions/kpiScoresAction";
import { approvalCreateAction } from "../../../redux/actions/approvalAction";
import AddQuarterlyValue from "./AddQuarterlyValue";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MenuBookIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const kpiList = [
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
  {
    id: 1,
    kpi: "abcd",
  },
];

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, item, userData } = props;
  const [updatedKpi, setUpdatedKpi] = React.useState([]);

  const dispatch = useDispatch();

  const kpi = useSelector((state) => state.kpi);
  const { kpis } = kpi;

  console.log("kpi_id", userData);

  const kpi_id_filter = kpis
    .filter((obj) => obj.kpi.includes(item.kpi))
    .map((obj) => obj.id);

  const entry_user = userData.user_id;
  const kpi_id = kpi_id_filter[0];

  console.log("kpi_id444", kpi_id);
  console.log("entry", entry_user);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(kpiScoresUpdateAction(updatedKpi));
    dispatch(approvalCreateAction(kpi_id, entry_user));
    setOpenPopup(false);
  };

  const handleClose = () => {
    setOpenPopup(false);
    // resetForm();
  };

  useEffect(() => {
    dispatch(kpisList());
  }, []);

  console.log("pppp", JSON.stringify(updatedKpi));

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      contentStyle={{
        width: "80%",
        maxWidth: "none",
      }}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        KPI Scores
      </DialogTitle>
      <DialogContent dividers>
        <AddQuarterlyValue
          kpiList={kpiList}
          item={item}
          setUpdatedKpi={setUpdatedKpi}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
