import axios from "axios";
import {
  EMPLOYEE_CATEGORIES_REQUEST,
  EMPLOYEE_CATEGORIES_SUCCESS,
  EMPLOYEE_CATEGORIES_FAIL,
  EMPLOYEE_CATEGORIES_CREATE_REQUEST,
  EMPLOYEE_CATEGORIES_CREATE_SUCCESS,
  EMPLOYEE_CATEGORIES_CREATE_FAIL,
  EMPLOYEE_CATEGORIES_UPDATE_REQUEST,
  EMPLOYEE_CATEGORIES_UPDATE_SUCCESS,
  EMPLOYEE_CATEGORIES_UPDATE_FAIL,
  EMPLOYEE_CATEGORIES_DELETE_REQUEST,
  EMPLOYEE_CATEGORIES_DELETE_SUCCESS,
  EMPLOYEE_CATEGORIES_DELETE_FAIL,
  EMPLOYEE_CATEGORIES_PERMISSION_REQUEST,
  EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS,
  EMPLOYEE_CATEGORIES_PERMISSION_FAIL,
} from "../constants/categoryConstant";

export const categoryList = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CATEGORIES_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/employeecategories"
    );

    dispatch({
      type: EMPLOYEE_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CATEGORIES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const categoryCreateAction =
  (category, alias, print_serial, created_by, modified_by) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CATEGORIES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/employeecategories",
        {
          category,
          alias,
          print_serial,
          // created_by,
          // modified_by,
        },
        config
      );

      console.log(
        "data4",
        category,
        alias,
        print_serial,
        // created_by,
        // modified_by
      );

      dispatch({
        type: EMPLOYEE_CATEGORIES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CATEGORIES_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const categoryUpdateAction =
  (
    id,
    category,
    alias,
    print_serial,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CATEGORIES_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/employeecategories/${id}`,
        {
          category,
          alias,
          print_serial,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        category,
        alias,
        print_serial,
        // created_by,
        // modified_by
      );

      dispatch({
        type: EMPLOYEE_CATEGORIES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CATEGORIES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const categoryDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CATEGORIES_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/employeecategories/${id}`,
      config
    );

    dispatch({
      type: EMPLOYEE_CATEGORIES_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CATEGORIES_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const departmentPermissionAction =
  (user_role_id, app_menu_id) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_CATEGORIES_PERMISSION_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      console.log("DataCheck", user_role_id, app_menu_id);

      const { data } = await axios.post(
        "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
        {
          user_role_id: user_role_id,
          app_menu_id: app_menu_id,
        },
        config
      );

      console.log("DataP", user_role_id, app_menu_id);

      dispatch({
        type: EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EMPLOYEE_CATEGORIES_PERMISSION_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
