export const BALANCE_DIMENTIONS_REQUEST = "BALANCE_DIMENTIONS_REQUEST";
export const BALANCE_DIMENTIONS_SUCCESS = "BALANCE_DIMENTIONS_SUCCESS";
export const BALANCE_DIMENTIONS_FAIL = "BALANCE_DIMENTIONS_FAIL";

export const BALANCE_DIMENTIONS_CREATE_REQUEST =
  "BALANCE_DIMENTIONS_CREATE_REQUEST";
export const BALANCE_DIMENTIONS_CREATE_SUCCESS =
  "BALANCE_DIMENTIONS_CREATE_SUCCESS";
export const BALANCE_DIMENTIONS_CREATE_FAIL = "BALANCE_DIMENTIONS_CREATE_FAIL";
export const BALANCE_DIMENTIONS_CREATE_RESET =
  "BALANCE_DIMENTIONS_CREATE_RESET";

export const BALANCE_DIMENTIONS_UPDATE_REQUEST =
  "BALANCE_DIMENTIONS_UPDATE_REQUEST";
export const BALANCE_DIMENTIONS_UPDATE_SUCCESS =
  "BALANCE_DIMENTIONS_UPDATE_SUCCESS";
export const BALANCE_DIMENTIONS_UPDATE_FAIL = "BALANCE_DIMENTIONS_UPDATE_FAIL";
export const BALANCE_DIMENTIONS_UPDATE_RESET =
  "BALANCE_DIMENTIONS_UPDATE_RESET";

export const BALANCE_DIMENTIONS_DELETE_REQUEST =
  "BALANCE_DIMENTIONS_DELETE_REQUEST";
export const BALANCE_DIMENTIONS_DELETE_SUCCESS =
  "BALANCE_DIMENTIONS_DELETE_SUCCESS";
export const BALANCE_DIMENTIONS_DELETE_FAIL = "BALANCE_DIMENTIONS_DELETE_FAIL";

export const BALANCE_DIMENTIONS_PERMISSION_REQUEST =
  "BALANCE_DIMENTIONS_PERMISSION_REQUEST";
export const BALANCE_DIMENTIONS_PERMISSION_SUCCESS =
  "BALANCE_DIMENTIONS_PERMISSION_SUCCESS";
export const BALANCE_DIMENTIONS_PERMISSION_FAIL =
  "BALANCE_DIMENTIONS_PERMISSION_FAIL";
