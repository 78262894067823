import { TOGGLE_SIDEBAR } from "../constants/globalStateConstants";

const initialState = {
  isSidebarOpened: true,
};

export const toggleSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { isSidebarOpened: !state.isSidebarOpened };
    default:
      return state;
  }
};
