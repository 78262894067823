import axios from "axios";
import {
  APPROVAL_LIST_REQUEST,
  APPROVAL_LIST_SUCCESS,
  APPROVAL_LIST_FAIL,
  APPROVAL_FORWARD_REQUEST,
  APPROVAL_FORWARD_SUCCESS,
  APPROVAL_FORWARD_FAIL,
  APPROVAL_MODIFY_REQUEST,
  APPROVAL_MODIFY_SUCCESS,
  APPROVAL_MODIFY_FAIL,
  APPROVAL_CREATE_REQUEST,
  APPROVAL_CREATE_SUCCESS,
  APPROVAL_CREATE_FAIL,
  APPROVAL_ENTRY_LIST_REQUEST,
  APPROVAL_ENTRY_LIST_SUCCESS,
  APPROVAL_ENTRY_LIST_FAIL,
  APPROVAL_FORWARD_NEXT_REQUEST,
  APPROVAL_FORWARD_NEXT_SUCCESS,
  APPROVAL_FORWARD_NEXT_FAIL,
} from "../constants/approvalConstant";

export const approvalList = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVAL_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `http://kpi.clonestudiobd.com/api/kpi-approval-approval/${user_id}`
    );

    dispatch({
      type: APPROVAL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const approvalForwardNextList =
  (kpi_id, entry_user) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_FORWARD_NEXT_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpiapproval/approval-pannel-members",
        {
          kpi_id,
          entry_user,
        },
        config
      );

      dispatch({
        type: APPROVAL_FORWARD_NEXT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_FORWARD_NEXT_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

export const approvalEntryList = (user_id) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVAL_ENTRY_LIST_REQUEST,
    });

    const { data } = await axios.get(
      `http://kpi.clonestudiobd.com/api/kpi-approval-entry/${user_id}`
    );

    dispatch({
      type: APPROVAL_ENTRY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_ENTRY_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const approvalCreateAction =
  (kpi_id, entry_user) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      console.log("postttt", kpi_id, entry_user);

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpiapproval",
        {
          kpi_id,
          entry_user,
        },
        config
      );

      dispatch({
        type: APPROVAL_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const approvalUpdateAction =
  (id, status, remarks) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_MODIFY_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const approval_status = status;

      const { data } = await axios
        .post(
          `http://kpi.clonestudiobd.com/api/kpiapproval/${id}`,
          {
            id,
            approval_status,
            remarks,
          },
          config
        )
        .then((res) => {
          console.log("response", res.data);
        });

      dispatch({
        type: APPROVAL_MODIFY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_MODIFY_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const approvalForwardAction =
  (kpi_id, entry_user, approval_status, remarks, entry_type, employee_id) =>
  async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_FORWARD_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const approval_user = employee_id;

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/kpiapproval/forward",
        {
          kpi_id,
          entry_user,
          approval_status,
          remarks,
          entry_type,
          approval_user,
        },
        config
      );

      dispatch({
        type: APPROVAL_FORWARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_FORWARD_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };
