import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { userList } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  approvalList,
  approvalForwardAction,
  approvalUpdateAction,
  approvalForwardNextList,
} from "../redux/actions/approvalAction";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  selectEmpty: {
    marginTop: theme.spacing(12),
  },
}));

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, approvalPending } = props;
  const [isForwarded, setIsForwarded] = useState(false);
  const [employee_id, setEmployeeId] = useState("");
  const [id, setId] = useState("");
  const [kpi_id, set_kpi_id] = useState("");
  const [entry_user, set_entry_user] = useState("");
  const [approval_status, set_approval_status] = useState("");
  const [remarks, set_remarks] = useState("");
  const [entry_type, set_entry_type] = useState("");

  useEffect(() => {
    if (approvalPending.length !== 0) {
      setId(approvalPending[0].id);
      set_kpi_id(approvalPending[0].kpi_id);
      set_entry_user(approvalPending[0].entry_user);
      set_approval_status(approvalPending[0].approval_status);
      set_remarks(approvalPending[0].remarks);
      set_entry_type(approvalPending[0].entry_type);
    }
  }, [approvalPending]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { users } = user;
  const approvalForwardNext = useSelector((state) => state.approvalForwardNext);
  const { approvalForwardNexts } = approvalForwardNext;

  useEffect(() => {
    dispatch(userList());
  }, [dispatch]);

  useEffect(() => {
    if (kpi_id !== "" && entry_user !== "")
      dispatch(approvalForwardNextList(kpi_id, entry_user));
  }, [kpi_id, entry_user]);

  useEffect(() => {
    if (approvalForwardNexts.length !== 0) {
      setEmployeeId(approvalForwardNexts[0].user_id);
    }
  }, [approvalForwardNexts]);

  const user_id = "user-1";

  const submitForwardHandler = (e) => {
    e.preventDefault();
    const status = "Y";
    dispatch(
      approvalForwardAction(
        kpi_id,
        entry_user,
        approval_status,
        remarks,
        entry_type,
        employee_id
      )
    );
    dispatch(approvalUpdateAction(id, status));
    setOpenPopup(false);
    setTimeout(() => {
      dispatch(approvalList(user_id));
    }, 2000);
  };

  const submitDeniedHandler = (e) => {
    e.preventDefault();
    const status = "C";
    dispatch(approvalUpdateAction(id, status, remarks));
    setOpenPopup(false);
    setTimeout(() => {
      dispatch(approvalList(user_id));
    }, 2000);
  };

  const handleClose = () => {
    setOpenPopup(false);
    setIsForwarded(false);
  };

  console.log("forwarded", approvalForwardNexts);
  console.log("pending details", approvalPending);
  console.log(
    "forward post",
    kpi_id,
    entry_user,
    approval_status,
    remarks,
    entry_type,
    employee_id
  );
  console.log("kpi_id", kpi_id);
  console.log("entry_user", entry_user);
  console.log("approval_status", approval_status);
  console.log("remarks", remarks);
  console.log("entry_type", entry_type);
  console.log("employee_id", employee_id);

  const handleInputChange = (event) => {
    set_remarks(event.target.value);
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Approval Action
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Typography variant="caption">LOB Revenue Vs. target</Typography>
            <Typography variant="title">January Month Score 70</Typography>
          </div>

          {isForwarded !== true ? (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Next Approval
                  </InputLabel>

                  <Select
                    native
                    value={employee_id}
                    onChange={(e) => setEmployeeId(e.target.value)}
                    label="Next Approval"
                    inputProps={{
                      name: "Next Approval",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value="None"></option>
                    {approvalForwardNexts
                      ? approvalForwardNexts.map((item) => (
                          <option value={item.user_id}>
                            {item.first_name} {item.last_name}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="remarks"
                  variant="outlined"
                  fullWidth
                  id="remarks"
                  label="Remarks *"
                  autoFocus
                  multiline
                  rows={4}
                  size="small"
                  // defaultValue={item ? item.description : null}
                  value={remarks}
                  error={remarks !== "" ? false : "Error"}
                  helperText={remarks !== "" ? false : "Please Write a Remark."}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>

      <DialogActions style={{ padding: 20 }}>
        {isForwarded === true ? (
          <div>
            {" "}
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={() => setIsForwarded(false)}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={submitDeniedHandler}
            >
              Send
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              autoFocus
              onClick={handleClose}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={submitForwardHandler}
            >
              Approve
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => setIsForwarded(true)}
            >
              Deny
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
