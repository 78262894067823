export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST";
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS";
export const ORGANIZATION_FAIL = "ORGANIZATION_FAIL";

export const ORGANIZATION_CREATE_REQUEST = "ORGANIZATION_CREATE_REQUEST";
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS";
export const ORGANIZATION_CREATE_FAIL = "ORGANIZATION_CREATE_FAIL";
export const ORGANIZATION_CREATE_RESET = "ORGANIZATION_CREATE_RESET";

export const ORGANIZATION_UPDATE_REQUEST = "ORGANIZATION_UPDATE_REQUEST";
export const ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS";
export const ORGANIZATION_UPDATE_FAIL = "ORGANIZATION_UPDATE_FAIL";
export const ORGANIZATION_UPDATE_RESET = "ORGANIZATION_UPDATE_RESET";

export const ORGANIZATION_DELETE_REQUEST = "ORGANIZATION_DELETE_REQUEST";
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS";
export const ORGANIZATION_DELETE_FAIL = "ORGANIZATION_DELETE_FAIL";

export const ORGANIZATION_PERMISSION_REQUEST =
  "ORGANIZATION_PERMISSION_REQUEST";
export const ORGANIZATION_PERMISSION_SUCCESS =
  "ORGANIZATION_PERMISSION_SUCCESS";
export const ORGANIZATION_PERMISSION_FAIL = "ORGANIZATION_PERMISSION_FAIL";
