import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setLastName,
    setRoleId,
    setEmployeeId,
    setPhoto,
    user_status,
    setUserStatus,
    item,
    departments,
    setSignPhoto,
    value,
    handleInputChange,
    error,
    helperText,
  } = props;

  console.log("edit data", item);

  const handleChange = (event) => {
    setUserStatus(event.target.value);
  };

  var deptName = item
    ? departments
        .filter((o1) => {
          return o1.id === item.department_id;
        })
        .map((item) => item.department_name)
    : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink htmlFor="age-native-required">
                Department *
              </InputLabel>
              <NativeSelect
                onChange={(e) => setRoleId(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "age-native-required",
                }}
              >
                <option value="">{item ? deptName : "None"}</option>
                {departments
                  ? departments.map((item) => (
                      <option value={item.id}>{item.department_name}</option>
                    ))
                  : null}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="chapter_no"
              variant="outlined"
              required
              fullWidth
              id="chapter_no"
              label="Section Name"
              autoFocus
              size="small"
              error={error.chapter_no}
              helperText={helperText.chapter_no}
              defaultValue={item ? item.section_name : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="chapter_title"
              required
              variant="outlined"
              fullWidth
              id="chapter_title"
              label="Section Alias"
              autoFocus
              size="small"
              error={error.chapter_title}
              helperText={helperText.chapter_title}
              defaultValue={item ? item.section_alias : null}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="print_serial"
              variant="outlined"
              fullWidth
              id="user_id"
              label="Print Serial"
              autoFocus
              size="small"
              error={error.print_serial}
              helperText={helperText.print_serial}
              defaultValue={item ? item.print_serial : null}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;
