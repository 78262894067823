import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  departmentList,
  departmentPermissionAction,
} from "../../../redux/actions/departmentAction";
import { approvalPanelList } from "../../../redux/actions/approvalPanelAction";
import { permissionAction } from "../../../redux/actions/permissionActions";

import SettingsIcon from "@material-ui/icons/Settings";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(45);

  const department = useSelector((state) => state.department);

  const { departments } = department;
  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  const approvalPanel = useSelector((state) => state.approvalPanel);

  const { approvalPanels } = approvalPanel;
  //   const departmentPermissions = useSelector(
  //     (state) => state.departmentPermission
  //   );
  //   const { departmentPermission } = departmentPermissions;

  //   console.log("permission - xyz", departmentPermissions);
  console.log(userData);

  useEffect(() => {
    dispatch(departmentList());
    dispatch(approvalPanelList());
    // dispatch(departmentPermissionAction(userData, app_menu_id));
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SettingsIcon style={{ fontSize: 25, marginTop: 25 }}>
          {/* settings */}
        </SettingsIcon>
        <h2 style={{ marginLeft: 5 }}>Department</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        {/* <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon> */}
        <NavigateNextIcon style={{ fontSize: 14, marginTop: 12 }}>
          navigate_next
        </NavigateNextIcon>
        <p> Department</p>
      </div>

      <div style={{ marginTop: 20 }}>
        {/* <Table departments={departments} /> */}
        <Table
          departments={departments}
          permissions={permissions}
          approvalPanels={approvalPanels}
        />
      </div>
    </div>
  );
};

export default Body;
