import {
  KPIS_REQUEST,
  KPIS_SUCCESS,
  KPIS_FAIL,
  KPIS_CREATE_REQUEST,
  KPIS_CREATE_SUCCESS,
  KPIS_CREATE_FAIL,
  KPIS_CREATE_RESET,
  KPIS_UPDATE_REQUEST,
  KPIS_UPDATE_SUCCESS,
  KPIS_UPDATE_FAIL,
  KPIS_UPDATE_RESET,
  KPIS_DELETE_REQUEST,
  KPIS_DELETE_SUCCESS,
  KPIS_DELETE_FAIL,
} from "../constants/kpisConstant";

export const kpisReducer = (state = { kpis: [] }, action) => {
  switch (action.type) {
    case KPIS_REQUEST:
      return { loading: true, kpis: [] };
    case KPIS_SUCCESS:
      return { loading: false, kpis: action.payload };
    case KPIS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const kpisCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPIS_CREATE_REQUEST:
      return { loading: true };
    case KPIS_CREATE_SUCCESS:
      return { loading: false, kpisCreates: action.payload };
    case KPIS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case KPIS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const kpisUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KPIS_UPDATE_REQUEST:
      return { loading: true };
    case KPIS_UPDATE_SUCCESS:
      return { loading: false, kpisUpdates: action.payload };
    case KPIS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case KPIS_UPDATE_RESET:
      return { kpisCreates: {} };
    default:
      return state;
  }
};

export const kpisDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KPIS_DELETE_REQUEST:
      return { loading: true };
    case KPIS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case KPIS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
