import axios from "axios";
import {
  SCORE_CARD_MONTH_REQUEST,
  SCORE_CARD_MONTH_SUCCESS,
  SCORE_CARD_MONTH_FAIL,
} from "../constants/scoreCardConstant";

export const scoreCardMonthList = () => async (dispatch) => {
  try {
    dispatch({
      type: SCORE_CARD_MONTH_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      // "http://kpi.clonestudiobd.com/api/kpiscores/monthly-scores",
      "http://kpi.clonestudiobd.com/api/kpiscores/monthly-scores",

      { kpi_year_id: "3", employee_id: "18" },
      config
    );

    dispatch({
      type: SCORE_CARD_MONTH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SCORE_CARD_MONTH_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
