import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImg from "../assets/images/background.jpg";
import logo from "../assets/images/c-logo.png";
import { green } from '@material-ui/core/colors';
import Notification from "./Notification";
import CircularProgress from '@material-ui/core/CircularProgress';


import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/userActions";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://clonestudiobd.com/">
        Clone Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginGrid: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  paper: {
    margin: theme.spacing(1, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(2),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '40%',
    marginTop: -10,
    marginLeft: -12,
  },
}));

const SignIn = ({ location, history }) => {
  const classes = useStyles();

  const [user_id, setUser_id] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [isLoading, setLoading] = React.useState(false);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const redirect = location.search ? location.search.split("=")[1] : "/app";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setNotify({
        isOpen: true,
        message: "Invalid User or Password!",
        type: "error",
      });
      setLoading(false)
    }
  }, [history, userInfo, error, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(user_id, password));
    setLoading(true)
  };

  console.log("data", userInfo);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={4} />
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.loginGrid}
      >
        <div className={classes.paper}>
          <Avatar src={logo} className={classes.avatar} />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              id="email"
              label="User Name"
              name="user name"
              autoComplete="email"
              autoFocus
              value={user_id}
              onChange={(e) => setUser_id(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox size="small" value="remember" color="primary" />
                  }
                  label={<p style={{ fontSize: 14, margin: 0 }}>Remember me</p>}
                />
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <div style={{position:'relative'}}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitHandler}
            >
              Sign In
            </Button>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>


            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={4} />
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default SignIn;
