import {
  EMPLOYEE_CATEGORIES_REQUEST,
  EMPLOYEE_CATEGORIES_SUCCESS,
  EMPLOYEE_CATEGORIES_FAIL,
  EMPLOYEE_CATEGORIES_CREATE_REQUEST,
  EMPLOYEE_CATEGORIES_CREATE_SUCCESS,
  EMPLOYEE_CATEGORIES_CREATE_FAIL,
  EMPLOYEE_CATEGORIES_CREATE_RESET,
  EMPLOYEE_CATEGORIES_UPDATE_REQUEST,
  EMPLOYEE_CATEGORIES_UPDATE_SUCCESS,
  EMPLOYEE_CATEGORIES_UPDATE_FAIL,
  EMPLOYEE_CATEGORIES_UPDATE_RESET,
  EMPLOYEE_CATEGORIES_DELETE_REQUEST,
  EMPLOYEE_CATEGORIES_DELETE_SUCCESS,
  EMPLOYEE_CATEGORIES_DELETE_FAIL,
  EMPLOYEE_CATEGORIES_PERMISSION_REQUEST,
  EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS,
  EMPLOYEE_CATEGORIES_PERMISSION_FAIL,
} from "../constants/categoryConstant";

export const categoryReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_REQUEST:
      return { loading: true, categories: [] };
    case EMPLOYEE_CATEGORIES_SUCCESS:
      return { loading: false, categories: action.payload };
    case EMPLOYEE_CATEGORIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_CREATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_CATEGORIES_CREATE_SUCCESS:
      return { loading: false, categoryCreates: action.payload };
    case EMPLOYEE_CATEGORIES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_CATEGORIES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_UPDATE_REQUEST:
      return { loading: true };
    case EMPLOYEE_CATEGORIES_UPDATE_SUCCESS:
      return { loading: false, categoryUpdates: action.payload };
    case EMPLOYEE_CATEGORIES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_CATEGORIES_UPDATE_RESET:
      return { departmentCreates: {} };
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_DELETE_REQUEST:
      return { loading: true };
    case EMPLOYEE_CATEGORIES_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EMPLOYEE_CATEGORIES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryPermissionReducer = (
  state = { categoryPermission: [] },
  action
) => {
  switch (action.type) {
    case EMPLOYEE_CATEGORIES_PERMISSION_REQUEST:
      return { loading: true, categoryPermission: [] };
    case EMPLOYEE_CATEGORIES_PERMISSION_SUCCESS:
      return { loading: false, categoryPermission: action.payload };
    case EMPLOYEE_CATEGORIES_PERMISSION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
