import axios from "axios";
import {
  KPI_SCORES_REQUEST,
  KPI_SCORES_SUCCESS,
  KPI_SCORES_FAIL,
  KPI_SCORES_CREATE_REQUEST,
  KPI_SCORES_CREATE_SUCCESS,
  KPI_SCORES_CREATE_FAIL,
  KPI_SCORES_CREATE_RESET,
  KPI_SCORES_UPDATE_REQUEST,
  KPI_SCORES_UPDATE_SUCCESS,
  KPI_SCORES_UPDATE_FAIL,
  KPI_SCORES_UPDATE_RESET,
  KPI_SCORES_DELETE_REQUEST,
  KPI_SCORES_DELETE_SUCCESS,
  KPI_SCORES_DELETE_FAIL,
} from "../constants/kpiScoresConstant";

export const kpiScoresUpdateAction = (updatedKpi) => async (dispatch) => {
  try {
    dispatch({
      type: KPI_SCORES_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("jjjj", updatedKpi);

    const { data } = await axios.post(
      "http://kpi.clonestudiobd.com/api/kpiscores/bulk-update",
      updatedKpi,
      config
    );

    dispatch({
      type: KPI_SCORES_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: KPI_SCORES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.errors
          ? error.response.data.errors
          : error.errors,
    });
  }
};
