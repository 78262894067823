export const SCORE_CARD_MONTH_REQUEST = "SCORE_CARD_MONTH_REQUEST";
export const SCORE_CARD_MONTH_SUCCESS = "SCORE_CARD_MONTH_SUCCESS";
export const SCORE_CARD_MONTH_FAIL = "SCORE_CARD_MONTH_FAIL";

// export const SECTION_CREATE_REQUEST = "SECTION_CREATE_REQUEST";
// export const SECTION_CREATE_SUCCESS = "SECTION_CREATE_SUCCESS";
// export const SECTION_CREATE_FAIL = "SECTION_CREATE_FAIL";
// export const SECTION_CREATE_RESET = "SECTION_CREATE_RESET";

// export const SECTION_UPDATE_REQUEST = "SECTION_UPDATE_REQUEST";
// export const SECTION_UPDATE_SUCCESS = "SECTION_UPDATE_SUCCESS";
// export const SECTION_UPDATE_FAIL = "SECTION_UPDATE_FAIL";
// export const SECTION_UPDATE_RESET = "SECTION_UPDATE_RESET";

// export const SECTION_DELETE_REQUEST = "SECTION_DELETE_REQUEST";
// export const SECTION_DELETE_SUCCESS = "SECTION_DELETE_SUCCESS";
// export const SECTION_DELETE_FAIL = "SECTION_DELETE_FAIL";
