export const KPIS_REQUEST = "KPIS_REQUEST";
export const KPIS_SUCCESS = "KPIS_SUCCESS";
export const KPIS_FAIL = "KPIS_FAIL";

export const KPIS_CREATE_REQUEST = "KPIS_CREATE_REQUEST";
export const KPIS_CREATE_SUCCESS = "KPIS_CREATE_SUCCESS";
export const KPIS_CREATE_FAIL = "KPIS_CREATE_FAIL";
export const KPIS_CREATE_RESET = "KPIS_CREATE_RESET";

export const KPIS_UPDATE_REQUEST = "KPIS_UPDATE_REQUEST";
export const KPIS_UPDATE_SUCCESS = "KPIS_UPDATE_SUCCESS";
export const KPIS_UPDATE_FAIL = "KPIS_UPDATE_FAIL";
export const KPIS_UPDATE_RESET = "KPIS_UPDATE_RESET";

export const KPIS_DELETE_REQUEST = "KPIS_DELETE_REQUEST";
export const KPIS_DELETE_SUCCESS = "KPIS_DELETE_SUCCESS";
export const KPIS_DELETE_FAIL = "KPIS_DELETE_FAIL";
