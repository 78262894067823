import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  kpiYearsList,
  kpiYearsCreateAction,
} from "../../../redux/actions/kpiYearsAction";
// import { DEPARTMENT_CREATE_RESET } from "../constants/dimentionsConstant";
import { CreateValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <PersonAddIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h6"
        style={{ marginLeft: 20, fontSize: 16, fontWeight: "500" }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, kpiYears } = props;
  const [organization_id, setOrganizationId] = React.useState("");
  const [sub_section_id, setSubSectionId] = React.useState("");
  const [designation_id, setDesignationId] = React.useState("");
  const [employee_category_id, setEmployeeCategoryId] = React.useState("");
  const [supervisor_id, setSupervisorId] = React.useState("");
  const [department_id, setDepartmentId] = React.useState("");

  const device_code = "123456";
  const device_validation = "Y";

  const dispatch = useDispatch();
  const employeeCreate = useSelector((state) => state.employeeCreate);
  const { employeeCreates } = employeeCreate;
  console.log("employee", employeeCreates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("from_date" in fieldValues)
      temp.from_date = fieldValues.from_date ? "" : "This field is required.";

    if ("email_address" in fieldValues)
      temp.email_address = fieldValues.email_address
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const kpi_year = values.kpi_year;
  const from_date = values.from_date;
  const to_date = values.to_date;

  console.log("post data", kpi_year, from_date, to_date);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(kpiYearsCreateAction(kpi_year, from_date, to_date));
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(kpiYearsList());
      }, 500);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openPopup}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        KPI Years
      </DialogTitle>
      <DialogContent dividers>
        <InputForm
          kpiYears={kpiYears}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
        />
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
