import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "./Table";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { userList } from "../../../redux/actions/userActions";
import { userRoleList } from "../../../redux/actions/userRoleActions";
import { permissionAction } from "../../../redux/actions/permissionActions";
import Icon from "@material-ui/core/Icon";
import { employeesList } from "../../../redux/actions/employeeAction";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const { userData } = props;
  const [app_menu_id, setAppMenuId] = useState(49);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  const { roles } = role;

  const { users } = user;

  const employee = useSelector((state) => state.employee);
  const { employees } = employee;

  console.log("employee", employees);

  const permission = useSelector((state) => state.permission);

  const { permissions } = permission;

  console.log("permission", permissions);
  console.log(userData);

  useEffect(() => {
    dispatch(employeesList());
    dispatch(userList());
    dispatch(userRoleList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon style={{ fontSize: 25, marginTop: 18 }}>settings</Icon>
        <h2 style={{ marginLeft: 5 }}>Users</h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: -30,
          fontSize: 11,
          marginLeft: 25,
        }}
      >
        <p>Settings</p>
        <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
        <p> Users</p>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          users={users}
          roles={roles}
          permissions={permissions}
          employees={employees}
        />
      </div>
    </div>
  );
};

export default Body;
