import axios from "axios";
import {
  BALANCE_DIMENTIONS_REQUEST,
  BALANCE_DIMENTIONS_SUCCESS,
  BALANCE_DIMENTIONS_FAIL,
  BALANCE_DIMENTIONS_CREATE_REQUEST,
  BALANCE_DIMENTIONS_CREATE_SUCCESS,
  BALANCE_DIMENTIONS_CREATE_FAIL,
  BALANCE_DIMENTIONS_CREATE_RESET,
  BALANCE_DIMENTIONS_UPDATE_REQUEST,
  BALANCE_DIMENTIONS_UPDATE_SUCCESS,
  BALANCE_DIMENTIONS_UPDATE_FAIL,
  BALANCE_DIMENTIONS_UPDATE_RESET,
  BALANCE_DIMENTIONS_DELETE_REQUEST,
  BALANCE_DIMENTIONS_DELETE_SUCCESS,
  BALANCE_DIMENTIONS_DELETE_FAIL,
  BALANCE_DIMENTIONS_PERMISSION_REQUEST,
  BALANCE_DIMENTIONS_PERMISSION_SUCCESS,
  BALANCE_DIMENTIONS_PERMISSION_FAIL,
} from "../constants/dimentionsConstant";

export const dimentionsList = () => async (dispatch) => {
  try {
    dispatch({
      type: BALANCE_DIMENTIONS_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/balancedimentions"
    );

    dispatch({
      type: BALANCE_DIMENTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BALANCE_DIMENTIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const dimentionsCreateAction =
  (dimention, description, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: BALANCE_DIMENTIONS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/balancedimentions",
        {
          dimention,
          description,
          created_by,
          modified_by,
        },
        config
      );

      console.log("data4", dimention, description, created_by, modified_by);

      dispatch({
        type: BALANCE_DIMENTIONS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BALANCE_DIMENTIONS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const dimentionsUpdateAction =
  (id, dimention, description, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: BALANCE_DIMENTIONS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `http://kpi.clonestudiobd.com/api/balancedimentions/${id}`,
        {
          dimention,
          description,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        dimention,
        description,
        created_by,
        modified_by
      );

      dispatch({
        type: BALANCE_DIMENTIONS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BALANCE_DIMENTIONS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const dimentionsDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BALANCE_DIMENTIONS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/balancedimentions/${id}`,
      config
    );

    dispatch({
      type: BALANCE_DIMENTIONS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BALANCE_DIMENTIONS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// export const dimentionsPermissionAction =
//   (user_role_id, app_menu_id) => async (dispatch) => {
//     try {
//       dispatch({
//         type: BALANCE_DIMENTIONS_PERMISSION_REQUEST,
//       });

//       const config = {
//         headers: {
//           Accept: "application/json",
//         },
//       };

//       console.log("DataCheck", user_role_id, app_menu_id);

//       const { data } = await axios.post(
//         "http://bbox.clonestudiobd.com/api/userrolefunctions/permission",
//         {
//           user_role_id: user_role_id,
//           app_menu_id: app_menu_id,
//         },
//         config
//       );

//       console.log("DataP", user_role_id, app_menu_id);

//       dispatch({
//         type: BALANCE_DIMENTIONS_PERMISSION_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: BALANCE_DIMENTIONS_PERMISSION_FAIL,
//         payload:
//           error.response && error.response.data.error
//             ? error.response.data.error
//             : error.error,
//       });
//     }
//   };
