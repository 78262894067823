import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./components/redux/reducers/userReducers";
import {
  moduleReducerGlobal,
  moduleReducerSidebar,
} from "./components/redux/reducers/moduleReducers";
import {
  userRoleReducer,
  userRoleCreateReducer,
  userRoleUpdateReducer,
  userRoleDeleteReducer,
  roleFunctionReducer,
  roleFunctionCreateReducer,
  roleFunctionUpdateReducer,
  roleFunctionDeleteReducer,
} from "./components/redux/reducers/userRoleReducers";

import {
  sectionReducer,
  sectionCreateReducer,
  sectionUpdateReducer,
  sectionDeleteReducer,
} from "./components/redux/reducers/sectionReducers";

import {
  employeeKpisReducer,
  employeeKpisWithIdReducer,
  employeeKpisCreateReducer,
  employeeKpisUpdateReducer,
  employeeKpisDeleteReducer,
  employeeWithKpiReducer,
} from "./components/redux/reducers/employeeKpisReducers";

import {
  kpisReducer,
  kpisCreateReducer,
  kpisUpdateReducer,
  kpisDeleteReducer,
} from "./components/redux/reducers/kpisReducer";

import { scoreCardMonthReducer } from "./components/redux/reducers/scoreCardReducer";

import {
  kpiYearsReducer,
  kpiYearsCreateReducer,
  kpiYearsUpdateReducer,
  kpiYearsDeleteReducer,
} from "./components/redux/reducers/kpiYearsReducer";

import {
  subSectionReducer,
  subSectionCreateReducer,
  subSectionUpdateReducer,
  subSectionDeleteReducer,
} from "./components/redux/reducers/subSectionReducers";

import {
  departmentReducer,
  departmentCreateReducer,
  departmentUpdateReducer,
  departmentDeleteReducer,
  departmentPermissionReducer,
} from "./components/redux/reducers/departmentReducer";

import {
  organizationReducer,
  organizationCreateReducer,
  organizationUpdateReducer,
  organizationDeleteReducer,
  organizationPermissionReducer,
} from "./components/redux/reducers/organizationReducer";

import {
  objectiveReducer,
  objectiveCreateReducer,
  objectiveUpdateReducer,
  objectiveDeleteReducer,
} from "./components/redux/reducers/objectiveReducer";

import {
  dimentionsReducer,
  dimentionsCreateReducer,
  dimentionsUpdateReducer,
  dimentionsDeleteReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/dimentionsReducer";

import {
  categoryReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
  // categoryPermissionReducer,
} from "./components/redux/reducers/categoryReducer";

import {
  designationReducer,
  designationCreateReducer,
  designationUpdateReducer,
  designationDeleteReducer,
  // designationPermissionReducer,
} from "./components/redux/reducers/designationReducer";

import {
  employeeReducer,
  employeeCreateReducer,
  employeeUpdateReducer,
  employeeDeleteReducer,
  // organizationReducer,
  // subSectionReducer,
  // designationReducer,
  // categoryReducer,
  // departmentReducer,
  // dimentionsPermissionReducer,
} from "./components/redux/reducers/employeeReducer";

import {
  kpimappingReducer,
  kpimappingCreateReducer,
  kpimappingUpdateReducer,
  kpimappingDeleteReducer,
  // designationPermissionReducer,
} from "./components/redux/reducers/kpimappingsReducer";
import { permissionReducer } from "./components/redux/reducers/permissionReducers";

import { toggleSidebarReducer } from "./components/redux/reducers/globalStateReducers";

import {
  approvalReducer,
  approvalEntryReducer,
  approvalForwardNextReducer,
} from "./components/redux/reducers/approvalReducer";

import { approvalPanelReducer } from "./components/redux/reducers/approvalPanelReducer";
import { approvalPanelMemberReducer } from "./components/redux/reducers/approvalPanelMembersReducer";

const reducer = combineReducers({
  approvalPanel: approvalPanelReducer,
  approvalPanelMember: approvalPanelMemberReducer,
  userLogin: userLoginReducer,
  module: moduleReducerSidebar,
  moduleGlobal: moduleReducerGlobal,
  user: userListReducer,
  approval: approvalReducer,
  approvalEntry: approvalEntryReducer,
  approvalForwardNext: approvalForwardNextReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  leftSidebar: toggleSidebarReducer,
  permission: permissionReducer,
  role: userRoleReducer,
  roleCreate: userRoleCreateReducer,
  roleUpdate: userRoleUpdateReducer,
  roleDelete: userRoleDeleteReducer,
  roleFunction: roleFunctionReducer,
  roleFunctionCreate: roleFunctionCreateReducer,
  roleFunctionUpdate: roleFunctionUpdateReducer,
  roleFunctionDelete: roleFunctionDeleteReducer,
  departmentPermission: departmentPermissionReducer,
  department: departmentReducer,
  departmentCreate: departmentCreateReducer,
  departmentUpdate: departmentUpdateReducer,
  departmentDelete: departmentDeleteReducer,
  organizationPermission: organizationPermissionReducer,
  organization: organizationReducer,
  organizationCreate: organizationCreateReducer,
  organizationUpdate: organizationUpdateReducer,
  organizationDelete: organizationDeleteReducer,
  section: sectionReducer,
  sectionCreate: sectionCreateReducer,
  sectionUpdate: sectionUpdateReducer,
  sectionDelete: sectionDeleteReducer,
  kpi: kpisReducer,
  kpisCreate: kpisCreateReducer,
  kpisUpdate: kpisUpdateReducer,
  kpisDelete: kpisDeleteReducer,
  kpiYear: kpiYearsReducer,
  kpiYearCreate: kpiYearsCreateReducer,
  kpiYearUpdate: kpiYearsUpdateReducer,
  kpiYearDelete: kpiYearsDeleteReducer,
  employeeKpi: employeeKpisReducer,
  employeeKpisWithId: employeeKpisWithIdReducer,
  employeeWithKpi: employeeWithKpiReducer,
  employeeKpisCreate: employeeKpisCreateReducer,
  employeeKpisUpdate: employeeKpisUpdateReducer,
  employeeKpisDelete: employeeKpisDeleteReducer,
  subSection: subSectionReducer,
  subSectionCreate: subSectionCreateReducer,
  subSectionUpdate: subSectionUpdateReducer,
  sbSectionDelete: subSectionDeleteReducer,
  objectives: objectiveReducer,
  objectiveCreate: objectiveCreateReducer,
  objectiveUpdate: objectiveUpdateReducer,
  objectiveDelete: objectiveDeleteReducer,
  dimention: dimentionsReducer,
  dimentionsCreate: dimentionsCreateReducer,
  dimentionsUpdate: dimentionsUpdateReducer,
  dimentionsDelete: dimentionsDeleteReducer,
  category: categoryReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  designation: designationReducer,
  designationCreate: designationCreateReducer,
  designationUpdate: designationUpdateReducer,
  designationDelete: designationDeleteReducer,
  employee: employeeReducer,
  employeeCreate: employeeCreateReducer,
  employeeUpdate: employeeUpdateReducer,
  employeeDelete: employeeDeleteReducer,
  kpimapping: kpimappingReducer,
  kpimappingCreate: kpimappingCreateReducer,
  kpimappingUpdate: kpimappingUpdateReducer,
  kpimappingDelete: kpimappingDeleteReducer,
  month: scoreCardMonthReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
