import axios from "axios";
import {
  APPROVAL_PANEL_MEMBERS_REQUEST,
  APPROVAL_PANEL_MEMBERS_SUCCESS,
  APPROVAL_PANEL_MEMBERS_FAIL,
  APPROVAL_PANEL_MEMBERS_CREATE_REQUEST,
  APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_CREATE_FAIL,
  APPROVAL_PANEL_MEMBERS_CREATE_RESET,
  APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST,
  APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_UPDATE_FAIL,
  APPROVAL_PANEL_MEMBERS_DELETE_REQUEST,
  APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS,
  APPROVAL_PANEL_MEMBERS_DELETE_FAIL,
} from "../constants/approvalPanelMembersConstant";

export const approvalPanelMembersList = () => async (dispatch) => {
  try {
    dispatch({
      type: APPROVAL_PANEL_MEMBERS_REQUEST,
    });

    const { data } = await axios.get(
      "http://kpi.clonestudiobd.com/api/approvalpanelmembers"
    );

    dispatch({
      type: APPROVAL_PANEL_MEMBERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_PANEL_MEMBERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const approvalPanelMembersCreateAction =
  (postData) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_PANEL_MEMBERS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        "http://kpi.clonestudiobd.com/api/approvalpanelmembers",
        postData,
        config
      );

      dispatch({
        type: APPROVAL_PANEL_MEMBERS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_PANEL_MEMBERS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const approvalPanelMembersUpdateAction =
  (products) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVAL_PANEL_MEMBERS_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.put(
        `http://kpi.clonestudiobd.com/api/approvalpanelmembers`,
        products,
        config
      );

      dispatch({
        type: APPROVAL_PANEL_MEMBERS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_PANEL_MEMBERS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const approvalPanelMembersDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVAL_PANEL_MEMBERS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(
      `http://kpi.clonestudiobd.com/api/delete-with-panel-id/${id}`,
      config
    );

    dispatch({
      type: APPROVAL_PANEL_MEMBERS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_PANEL_MEMBERS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
